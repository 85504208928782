import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper';
import { CategorySlide } from '../CategorySlide';
import { CategoryBar } from '../CategoryBar';
import "../scss/Category.css"
import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import { PostBody, initPostBody } from '../type/type';

interface Props {
  category: string;
  large: string;
  latestPosts?: PostBody[];
}

// swiper of each category (mobile)
const CategorySwiper: React.FC<Props> = (props: Props) => {
  // first 3 category posts
  const firstThreeCategoryPosts = props.latestPosts ? props.latestPosts.slice(0, 3) : [initPostBody()];

  const initBackground = (swiper: any) => {
    swiper.slideTo(1);
  };

  return (
    <div className='Category-Swiper-Section-Mobile' style={{ minHeight: '490px', position: 'relative' }}>
      <CategoryBar category={props.category} />

      <div className={`${props.category}-Category-Swiper-Custom-Pagination-Wrapper`}>
        <div className={`${props.category}-Category-Swiper-Custom-Pagination`} />
      </div>

      <Swiper loop={true} id={`${props.category}-Category-Swiper-Mobile`} className='My-Swiper'
        observer={true}
        uniqueNavElements={false}
        onAfterInit={(swiper) => initBackground(swiper)}
        modules={[Navigation, Pagination]}
        pagination={{
          enabled: true,
          el: `.${props.category}-Category-Swiper-Custom-Pagination`,
          renderBullet: (index, className) => {
            return '<span class="' + className + '"></span>';
          },
        }}>
        {firstThreeCategoryPosts.map((post: PostBody) => (
          <SwiperSlide className={`${props.category} post-${post.id?.toString()}`} key={`${props.category}-swiper-${post.id}-key`} >
            <CategorySlide category={props.category} large={props.large} post={post} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div >
  );
}

export default CategorySwiper;
