import React, { useEffect, useRef } from "react";
import '../../../../common/scss/Common.css';
import '../../../../common/scss/LatestSwiper.css';
import '../../../../common/scss/Category.css';
import { Header } from '../../../../common/Header';
import { Footer } from '../../../../common/Footer';
import '../subscription.css'
import { gsap } from "gsap";
import { useParams } from "react-router";

function ConfirmationPage() {
  const subscriptionRef = useRef(null);
  const email = useParams();
  useEffect(() => {
    // fade in animation
    gsap.fromTo(subscriptionRef.current, { autoAlpha: 0, y: 50 }, { duration: 1, autoAlpha: 1.5, y: 0 })
  }, []);
  return (
    <div className="Page-Container">
      <Header />
      <div className="Page-Content">
        <div className='Content-Container'>
          <div className="Subscription-Content" ref={subscriptionRef}>
            <h1>Welcome to MORF, FROM now on!</h1>
            <p>Cool, you are now subscribed to morf.today and will receive an email notification when a new post is published with {email.email}.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ConfirmationPage;
