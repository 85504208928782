import { useEffect, useState, useContext } from 'react';
import axios, { AxiosResponse } from 'axios';
import { Latest } from './component/Latest';
import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import '../scss/Category.css';
import { LoadingContext } from '../Loading/component/Context';
import { initPostBody } from '../type/type';

// type and init
import {
  PostRequestBody,
  PostBody,
  Image,
  ImageContent,
  Category,
} from '../type/type';
import { initImageContent } from '../type/type';

export function generatePostBody(body: any): PostBody {
  return {
    id: body.id,
    attributes: {
      content: body.attributes.content,
      desktop_169:
        body.attributes.desktop_169?.data?.length > 0
          ? matchImageList(body.attributes.desktop_169.data)
          : [],
      square_89:
        body.attributes.square_89?.data
          ? matchImageList([body.attributes.square_89.data])
          : [],
      mobile_916:
        body.attributes.mobile_916?.data
          ? matchImageList([body.attributes.mobile_916.data])
          : [],
      createdAt: body.attributes.createdAt,
      description: body.attributes.description,
      publishedAt: body.attributes.publishedAt,
      title: body.attributes.title,
      updatedAt: body.attributes.updatedAt,
      media:
        body.attributes.media?.data?.length > 0
          ? matchImageList(body.attributes.media.data)
          : [],
      categories:
        body.attributes.categories && body.attributes.categories.data.length > 0
          ? matchCategoryList(body.attributes.categories.data)
          : [],
      subCategories:
        body.attributes.sub_categories &&
          body.attributes.sub_categories.data.length > 0
          ? matchCategoryList(body.attributes.sub_categories.data)
          : [],
      video: body.attributes.video,
      heading: body.attributes.heading,
      issue: body.attributes.issue,
    },
  };
}
export function matchCategoryList(data: any[]): Category[] {
  const result = data.map((item, data) => {
    return item.attributes;
  });
  return result;
}

export function matchImageList(data: any[]): Image[] {
  const result = data.map((item, index) => {
    const parsedItem = distributeImage(item);
    return parsedItem;
  });
  return result;
}

export function distributeImage(data: any): Image {
  return {
    id: data.id,
    name: data.attributes.name,
    caption: data.attributes.caption,
    alternativeText: data.attributes.alternativeText,
    normal: {
      name: data.attributes.name,
      url: data.attributes.url,
      height: data.attributes.height,
      width: data.attributes.width,
    },
    small: distributeImageContent(data.attributes, 'small'),
    large: distributeImageContent(data.attributes, 'large'),
    medium: distributeImageContent(data.attributes, 'medium'),
    thumbnail: distributeImageContent(data.attributes, 'thumbnail'),
  };
}

export function distributeImageContent(data: any, type: string): ImageContent {
  if (data.formats && data.formats[type]) {
    return {
      name: data.formats[type].name,
      url: data.formats[type].url,
      height: data.formats[type].height,
      width: data.formats[type].width,
    };
  } else {
    return initImageContent();
  }
}

export function handleResponseData(data: AxiosResponse | null): PostBody[] {
  var body: PostBody[] = [];
  if (data && data.data && data.data.data) {
    // check for existance fo the raw data, data.data.data should be list of PostBoday
    for (var i = 0; i < data.data.data.length; i++) {
      body.push(generatePostBody(data.data.data[i]));
    }
  }
  return body;
}

// Helper funciton, filtering result
export function filterResult(
  postBody: PostBody[],
  request: PostRequestBody
): PostBody[] {
  const categoryList: string[] = [
    'all',
    'people',
    'hair',
    'beauty',
    'fashion',
    'mind',
    'living',
    'morf',
  ];
  if (categoryList.indexOf(request.category) === -1 || postBody.length <= 0) {
    return [];
  }
  if (request.category === 'all' && request.numberOfRecord === -1) {
    return postBody;
  }
  var resultList = [];
  for (var i = 0; i < postBody.length; i++) {
    if (
      postBody[i].attributes.categories.length > 0 ||
      request.category === 'all'
    ) {
      var appear = false;
      for (var j = 0; j < postBody[i].attributes.categories.length; j++) {
        if (
          request.category === 'all' ||
          postBody[i].attributes.categories[j].name === request.category
        ) {
          appear = true;
          continue;
        }
      }
      if (
        appear &&
        (request.numberOfRecord === -1 ||
          (request.numberOfRecord >= 0 &&
            resultList.length < request.numberOfRecord))
      ) {
        resultList.push(postBody[i]);
      }
    }
  }
  return resultList;
}

// Helper funciton, filtering result
export function filterResultBySubcategory(
  postBody: PostBody[],
  subcategory: string
): PostBody[] {
  const subCategoryList: string[] = [
    'product',
    'hairstyle',
    'skin-care',
    'make-up',
    'brand-story',
    'styling',
    'news',
    'movie',
    'book',
    'design',
    'art',
    'culture',
    'food',
    'sport',
    'game',
    'travel',
    'about',
    'subscription',
    'contact',
    'privacy-policy',
    'cookies-policy',
  ];
  if (subCategoryList.indexOf(subcategory) === -1 || postBody.length <= 0) {
    return [];
  }
  var resultList = [];
  for (var i = 0; i < postBody.length; i++) {
    if (postBody[i].attributes.subCategories.length > 0) {
      var appear = false;
      for (var j = 0; j < postBody[i].attributes.subCategories.length; j++) {
        if (postBody[i].attributes.subCategories[j].name === subcategory) {
          appear = true;
          continue;
        }
      }
      if (appear) {
        resultList.push(postBody[i]);
      }
    }
  }
  return resultList;
}

export default function Post(request: PostRequestBody) {
  const { loadingFinish } = useContext(LoadingContext);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const url =
    'https://morf-prod.agileexlab.net/api/posts?populate=%2A&pagination[limit]=-1'; // get full raw data from API, including relations and media list
  const authentication = {
    headers: {
      Authorization:
        'Bearer ' +
        '436b7d7cd0b85ff27e2dde37c7255b0dd7c1791ed102543b6e2dacb57ec69eec708496ee2fd051a81b58a86dd1b44081e64e5cc3461b99740a3ce9ed2aa4bfbbb9f96b4db2197df862fc26cb8332693ea515179a287fd6f94f97628574af396ba4040b52e08d15a6adb4e401858c650d7ed508975f978b11cb210a76053554fe',
    },
  };
  const [fetchedData, setFetchedData] = useState<AxiosResponse | null>(null);
  useEffect(() => {
    const getData = async () => {
      const data = await axios
        .get(url, authentication)
        .then((res) => {
          setFetchedData(res);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
          loadingFinish();
        });
    };
    getData();
  }, []);
  const postList = handleResponseData(fetchedData);
  if (request.category === 'latest') {
    return Latest(postList, request.numberOfRecord);
  } else {
    return filterResult(postList, request);
  }
}

export function Article(postId: string): PostBody {
  const { loadingFinish } = useContext(LoadingContext);
  const [error, setError] = useState('');
  const url =
    'https://morf-prod.agileexlab.net/api/posts/' +
    postId +
    '?populate=%2A&pagination[limit]=-1';
  const authentication = {
    headers: {
      Authorization:
        'Bearer ' +
        '436b7d7cd0b85ff27e2dde37c7255b0dd7c1791ed102543b6e2dacb57ec69eec708496ee2fd051a81b58a86dd1b44081e64e5cc3461b99740a3ce9ed2aa4bfbbb9f96b4db2197df862fc26cb8332693ea515179a287fd6f94f97628574af396ba4040b52e08d15a6adb4e401858c650d7ed508975f978b11cb210a76053554fe',
    },
  };
  const [fetchedData, setFetchedData] = useState<AxiosResponse | null>(null);
  useEffect(() => {
    const getData = async () => {
      const data = await axios
        .get(url, authentication)
        .then((res) => {
          setFetchedData(res);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          loadingFinish();
        });
    };
    getData();
  }, []);
  const postList = fetchedData
    ? generatePostBody(fetchedData?.data.data)
    : initPostBody();
  return postList;
}