import React from "react";
import '../../../../common/scss/Common.css';
import '../../../../common/scss/LatestSwiper.css';
import '../../../../common/scss/Category.css';
import { Header } from '../../../../common/Header';
import { Footer } from '../../../../common/Footer';
import '../subscription.css'
import { Button } from "@mui/material";
import { useParams } from "react-router";
import { useEmail } from "../../../../common/Strapi";

function VerificationPage() {
  const { verifyEmail } = useEmail();
  const email = useParams();
  return (
    <div className="Page-Container">
      <Header />
      <div className="Page-Content">
        <div className='Content-Container'>
          <div className="Subscription-Content">
            <h1>Please confirm your subscription for MORF, FROM now on</h1>
            <p>You recently subscribed to morf.today and we need to verify the email you provided. Once you confirm below, you'll be able to receive and read new posts.</p>
            <Button variant="contained" color="primary" onClick={() => { verifyEmail({ email: `${email.email}` }); }}>Confirm now</Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default VerificationPage;
