import './component/header.css'
import logo from '../../assets/img/Morf_logo-03.webp';
// import logo from '../../assets/img/new-logo.png';
import hamburger from '../../assets/img/hamburger.webp';
// import hamburger from '../../assets/img/four-line-hamburger.png';
import { TemporaryDrawer } from '../Menudrawer';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import '../scss/Category.css';

function Header(currentPage: any) {
  const img_data = ['people', 'fashion', 'beauty', 'mind', 'hair', 'living'];

  const [right, setRight] = useState(false);

  const action = (set: any) => {
    setRight(set);
  };
  const navigate = useNavigate();

  return (
    <div className='header-container'>
      <div className='header-wrapper'>
        <span
          onClick={() => navigate('/')}
          className='header-logo-container'
          style={{ cursor: 'pointer' }}>
          <img className='header-logo' src={logo} alt={''} />
        </span>

        <div className='header-button-list'>
          {img_data.map((data, index) => (
            <div
              key={`header-element-${index}`}
              className='header-button-wrapper'
            >
              <span className='header-button-link' onClick={() => navigate('/' + data)} style={{ cursor: 'pointer' }}>
                <img
                  src={
                    currentPage.currentPage !== data &&
                      img_data.includes(currentPage.currentPage)
                      ? require('../../assets/img/' + data + '-hover.png')
                      : require('../../assets/img/' + data + '.png')
                  }
                  alt={''}
                  className={'header-button relative-override'}
                />

                <img
                  className={'header-button-hover'}
                  src={
                    currentPage.currentPage !== data &&
                      img_data.includes(currentPage.currentPage)
                      ? require('../../assets/img/' + data + '.png')
                      : require('../../assets/img/' + data + '-hover.png')
                  }
                  alt={''}
                />
              </span>
            </div>
          ))}
        </div>

        <div className='header-hamburger-container'>
          <img
            className='header-hamburger'
            src={hamburger}
            alt={''}
            onClick={action}
          />
        </div>
      </div>
      <TemporaryDrawer right={right} set={action}></TemporaryDrawer>
    </div>
  );
}

export default Header;
