import '../ArticlePageLayout.css';
import React, { useContext, useEffect } from 'react';
import '../../../../common/scss/Common.css';
import '../../../../common/scss/LatestSwiper.css';
import '../../../../common/scss/Category.css';
import { ArticleContentContainerMobile } from '../ArticleContentContainerMobile';
import { ArticleHeadingContainer } from '../ArticleHeadingContainer';
import { useParams } from 'react-router';
import { Article } from '../../../../common/Strapi';
import { initPostBody } from '../../../../common/type/type';
import { LoadingContext } from '../../../../common/Loading/component/Context';

import { ArticleSkeletonMobile } from '../ArticleSkeleton/ArticleSkeletonMobile';
import { ArticleSkeletonDesktop } from '../ArticleSkeleton/ArticleSkeletonDesktop';
import { TopButton } from '../../../../common/TopButton';

function ArticleContent() {
  const param = useParams();
  const articleContent = param.postId ? Article(param.postId) : initPostBody();
  const { loadingBool } = useContext(LoadingContext);
  const category =
    articleContent.attributes.categories[0].name !== ''
      ? articleContent.attributes.categories[0].name
      : 'fashion';
  const hashesBgPath = require(`../../../../assets/img/${category}-article-bg.png`);
  useEffect(() => {
    document.title = `${articleContent.attributes.title} – MORF, FROM now on`;
    const allArticleParagraphs = document.getElementsByClassName('Article-Text-Content')
    const allArticleParagraphsArr = Array.from(allArticleParagraphs)
    let videoId = ''
    if (articleContent.attributes.video) {
      const link = articleContent.attributes.video.split('/')
      // handle potential trailing slash
      videoId = link.pop() || link.pop()!
    }

    const findVideoIndicator = /(&lt;)(video)(&gt;)/gi;
    const findEmptyPTag = /<p><\/p>/gi;
    allArticleParagraphsArr.map((allArticleParagraph: any) => {
      let result = allArticleParagraph.innerHTML.replace(findVideoIndicator,
        `</p>
        <div className="Video-Container">
          <iframe className="Article-Embedded-Video"
            loading="lazy"
            style="aspect-ratio: 16/9;width:100%;margin: 30px 0px;border:0px;"
            src="https://www.youtube.com/embed/${videoId}"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
          </iframe>
        </div><p>`)
      result = result.replace(findEmptyPTag, '')
      allArticleParagraph.innerHTML = result
    })
  });
  return (
    <div className='Page-Content Article-bottom-padding'>
      <div className='Top-Spacing-Row'></div>
      <div className='background-limit'>
        {/* <div className='Ads-Container'>
          <div className='Ads-Left'>
            <Advertisement></Advertisement>
          </div>
          <div className='Ads-Right'>
            <Advertisement></Advertisement>
          </div>
        </div> */}
        <div
          className='Article-Page-Wrapper'
          style={{ backgroundImage: `url(${hashesBgPath})` }}
        >
          <div className='Content-Container Article-Page-Content'>
            {loadingBool ? (
              <ArticleSkeletonDesktop content={articleContent} />
            ) : (
              <ArticleHeadingContainer content={articleContent} />
            )}
            {loadingBool ? (
              <ArticleSkeletonMobile content={articleContent} />
            ) : (
              <ArticleContentContainerMobile content={articleContent} />
            )}
          </div>
        </div>
      </div>
      <TopButton category={category} />
    </div>
  );
}

export default ArticleContent;
