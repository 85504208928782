import React from "react";
import '../../../../common/scss/Common.css';
import '../../../../common/scss/LatestSwiper.css';
import '../../../../common/scss/Category.css';
import { Header } from '../../../../common/Header';
import { Footer } from '../../../../common/Footer';
import { Button } from "@mui/material";
import '../subscription.css'
import { useEmail } from "../../../../common/Strapi";
import { useParams } from "react-router";

function DeletePage() {
  const email = useParams();
  const { deleteEmail } = useEmail();
  return (
    <div className="Page-Container">
      <Header />
      <div className="Page-Content">
        <div className='Content-Container'>
          <div className="Subscription-Content">
            <h1>Delete Your Subscription</h1>
            <p>We're sorry that you've decided to stop your subscription. If you change your mind in the future we're only a few clicks away.</p>
            <Button variant="contained" color="error" onClick={() => deleteEmail({ email: `${email.email}` })}>Yes, delete my subscription</Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DeletePage;
