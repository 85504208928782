import { ImageGroup } from '../ImageGroup';
import { CategoryExcerptReadmore } from '../CategoryExcerptReadmore';
import React from 'react';
import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import '../scss/Category.css';
import { PostBody } from '../type/type';
import { ImageHandler } from '../ImageHandler';

interface Props {
  category: string;
  large: string;
  post: PostBody;
}

// slide of a post in category section (mobile)
const CategorySlide: React.FC<Props> = (props: Props) => {
  // first 3 images in a post
  const img1Path = ImageHandler('https://morf-prod.agileexlab.net', props.post?.attributes?.square_89[0], 1)
  const img2Path = ImageHandler('https://morf-prod.agileexlab.net', props.post?.attributes?.desktop_169[1], 1)
  const img3Path = ImageHandler('https://morf-prod.agileexlab.net', props.post?.attributes?.desktop_169[2], 1)

  const postTitle = props.post?.attributes?.title!;
  const postDesc = props.post?.attributes?.description!;
  const postId = props.post?.id!;

  return (
    <div>
      <ImageGroup
        large={props.large}
        img1={img1Path}
        img2={img2Path}
        img3={img3Path}
        id={postId}
      />
      <CategoryExcerptReadmore
        category={props.category}
        postTitle={postTitle}
        postDesc={postDesc}
        id={props.post?.id ? props.post.id : 6788}
      />
    </div>
  );
};

export default CategorySlide;
