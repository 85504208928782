import { Header } from '../../common/Header';
import { Footer } from '../../common/Footer';
import './component/ArticlePageLayout.css';
import { ArticleContent } from './component/ArticleContent';
import React from 'react';
import '../../common/scss/Common.css';
import '../../common/scss/LatestSwiper.css';
import '../../common/scss/Category.css';
import { LoadingProvider } from '../../common/Loading/component/Provider';
import { useParams } from 'react-router';
import { Article } from '../../common/Strapi';
import { initPostBody } from '../../common/type/type';

function ArticlePage() {
  const param = useParams();
  const articleContent = param.postId ? Article(param.postId) : initPostBody();
  const category =
    articleContent.attributes.categories[0].name !== ''
      ? articleContent.attributes.categories[0].name
      : '';

  return (
    <div className='Page-Container'>
      <LoadingProvider>
        <Header currentPage={category} />
        <ArticleContent />
        <Footer />
      </LoadingProvider>
    </div>
  );
}

export default ArticlePage;
