import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../../../../common/scss/Common.css';
import '../../ArticlePageLayout.css';

const ArticleSkeletonDesktop = (props: any) => {
  return (
    <div className='Article-Heading-Container'>
      <div className='Article-Heading' style={{ maxWidth: '920px' }}>
        <div className='Issue-Container'>
          <p className='Issue'>
            <Skeleton width={109} />
          </p>
          <p className='Issue-Number'>
            <Skeleton height={80} width={113} />
          </p>
        </div>

        <div className='Image-Column-desktop-wrapper'>
          <Skeleton height={1155} width={260} />
        </div>
        <div className='Article-Title-Container'>
          <div className='Article-Title-Wrapper'>
            <div className='Article-Category-Icon-Container '>
              <Skeleton height={37} />
            </div>
            <div className='Article-Title'>
              <Skeleton width={512} />
            </div>
          </div>
          <hr className='divider' style={{ color: '#ebebeb' }} />

          <div className='Text-Column-desktop'>
            <p className='Article-Text-Content'>
              <Skeleton count={40} width={455} style={{ marginTop: '5px' }} />
            </p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleSkeletonDesktop;
