import { useState, useRef, useEffect } from 'react';
import '../ArticlePageLayout.css';
import React from 'react';
import '../../../../common/scss/Common.css';
import '../../../../common/scss/LatestSwiper.css';
import '../../../../common/scss/Category.css';
import { Image } from '../../../../common/type/type';
import ReactMarkdown from 'react-markdown';

import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import 'yet-another-react-lightbox/plugins/captions.css';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import { ImageHandler } from '../../../../common/ImageHandler';
import { FallBackImage } from '../../../../common/FallBackImage';
import Skeleton from 'react-loading-skeleton';
function pad(num: number) {
  return (num < 10) ? '0' + num.toString() : num.toString();
}

function ArticleHeadingContainer(props: any) {
  const ref = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const [scrollStyle, setScrollStyle] = useState<string | null>();
  const [imageHeight, setImageHeight] = useState<number | null>();
  const loadedImgRef = useRef(0);
  const imgCol = document.getElementById('Image-Column-desktop')!;

  useEffect(() => {
    // As image-grp height is set as 1135px
    if (imageRef.current != null) {
      1135 - imageRef.current['clientHeight'] > 0
        ? setImageHeight(0)
        : setImageHeight(1135 - imageRef.current['clientHeight']);
    }
  });

  const scrollHandler = () => {
    var percentChanges = 0;
    if (ref.current != null) {
      percentChanges =
        (100 * ref.current['scrollTop']) /
        (ref.current['scrollHeight'] - ref.current['clientHeight']) /
        100;
    }

    if (imageRef.current != null) {
      imageRef.current['scrollHeight'] > 1135
        ? setScrollStyle(
          'translate3d(0, ' +
          (imageRef.current['scrollHeight'] - 1145) * percentChanges +
          'px, 0)'
        )
        : setScrollStyle('translate3d(0, 0px, 0)');
    }
  };

  const [openLightbox, setOpenLightbox] = React.useState(false);
  const [lightboxIndex, setLightboxIndex] = React.useState(-1);

  // an array that contains all images for the lightbox
  let imgSrcList: { src: string; title: string; description: string }[] = [];
  if (props?.content?.attributes?.media?.length > 1) {
    props.content.attributes.media.map((img: any, index: number) =>
      imgSrcList.push({
        src: ImageHandler('https://morf-prod.agileexlab.net', img, 2),
        title: `${index + 1}/${props.content.attributes.media.length}`,
        description: `${img.name}`,
      })
    );
  }

  let width;
  window.onresize = function () {
    width = window.innerWidth;
    if (width <= 1024) {
      setOpenLightbox(false);
    }
  };

  const loadImage = () => {
    loadedImgRef.current++;
    // console.log(loadedImgRef.current, props.content.attributes.media.length)
    if (loadedImgRef.current === props.content.attributes.media.length) {
      document.getElementById("Image-Column-desktop")!.style.display = "flex";
    }

    if (
      ref.current !== null &&
      imgCol !== null &&
      // check if text has been scrolled, and if all imgs finished loading
      ref.current['scrollTop'] === 0 &&
      loadedImgRef.current === props.content.attributes.media.length
    ) {
      imgCol.scrollIntoView(false);
      window.scrollTo(0, 0);
    }


  };
  const contentArr = props.content.attributes.content.split(/##(.*?)\n/g);
  // console.log(props.content.attributes.title)
  return (
    <div className='Article-Heading-Container'>
      <Lightbox
        open={openLightbox}
        index={lightboxIndex}
        close={() => setOpenLightbox(false)}
        slides={imgSrcList}
        controller={{
          closeOnBackdropClick: true,
        }}
        plugins={[Fullscreen, Captions, Zoom]}
      />
      {props.content.attributes.title.includes("現場直播") ? (
        <div className='Article-Heading' style={{ justifyContent: 'center' }}>
          {props?.content?.attributes?.categories[0].name ? (
            <div
              className={`Issue-Container ${props.content.attributes.categories[0].name}-color`}
            >
              <p className='Issue'>ISSUE</p>
              <p className='Issue-Number'>{pad(props.content.attributes.issue)}</p>
            </div>
          ) : (
            <></>
          )}
          <div className='Article-Title-Container'>
            <div className='Article-Title-Wrapper'>
              <div className='Article-Category-Icon-Container '>
                {props?.content?.attributes?.categories[0].name ? (
                  <img
                    className='Category-Icon'
                    src={require(`../../../../assets/img/${props.content.attributes.categories[0].name}-section-icon.png`)}
                    alt=''
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className='Article-Title'>
                {props && props.content && props.content.attributes
                  ? props.content.attributes.heading
                  : ''}
              </div>
            </div>
            {props?.content?.attributes?.categories[0].name ? (
              <hr
                className={`divider ${props.content.attributes.categories[0].name}-top-border`}
              />
            ) : (
              ''
            )}
            <div
              className='Text-Column-desktop'
              ref={ref}
              onScroll={scrollHandler}>

              {props.content.attributes.title.includes("現場直播") ? (
                <div className='Article-Text-Content'>
                  {/* <iframe src="//player.louisvuitton.com/embed/1486109" title="Louis Vuitton - Pre-Fall 2024 Men - Hong Kong - 16:9 Monobitrate" width="100%" height="300px" style={{ border: 0, left: 0, top: 0, overflow: 'auto' }} allowFullScreen></iframe> */}
                  {
                    contentArr.map((item: any, index: any) => {
                      if (index <= 6) {
                        if (index === 0) {
                          return (
                            <>
                              <ReactMarkdown
                                key={index}
                                children={item}
                                className={'React-Markdown-HTML'}
                              />
                            </>
                          )
                        }
                        if (index % 2 === 0) {
                          // Render the content as a ReactMarkdown component
                          return (
                            <ReactMarkdown
                              key={index}
                              children={item}
                              className={'React-Markdown-HTML'}
                            />
                          );
                        } else {
                          // Render the heading as a regular text element
                          return (
                            <>
                              <h2 key={index}>{item}</h2>
                            </>
                          );
                        }
                      }
                      return <></>
                    })
                  }
                  {
                    contentArr.map((item: any, index: any) => {
                      if (index > 6) {
                        if (index % 2 === 0) {
                          // Render the content as a ReactMarkdown component
                          return (
                            <ReactMarkdown
                              key={index}
                              children={item}
                              className={'React-Markdown-HTML'}
                            />
                          );
                        } else {
                          // Render the heading as a regular text element
                          return (
                            <>
                              <h2 key={index}>{item}</h2>
                            </>
                          );
                        }
                      }
                      return <></>
                    })
                  }
                </div>
              ) : (
                <div className='Article-Text-Content'>
                  {
                    contentArr.map((item: any, index: any) => {
                      if (index <= 6) {
                        if (index === 0) {
                          return (
                            <>
                              <ReactMarkdown
                                key={index}
                                children={item}
                                className={'React-Markdown-HTML'}
                              />
                            </>
                          )
                        }
                        if (index % 2 === 0) {
                          // Render the content as a ReactMarkdown component
                          return (
                            <ReactMarkdown
                              key={index}
                              children={item}
                              className={'React-Markdown-HTML'}
                            />
                          );
                        } else {
                          // Render the heading as a regular text element
                          return (
                            <>
                              <h2 key={index}>{item}</h2>
                            </>
                          );
                        }
                      }
                      return <></>
                    })
                  }
                  {
                    contentArr.map((item: any, index: any) => {
                      if (index > 6) {
                        if (index % 2 === 0) {
                          // Render the content as a ReactMarkdown component
                          return (
                            <ReactMarkdown
                              key={index}
                              children={item}
                              className={'React-Markdown-HTML'}
                            />
                          );
                        } else {
                          // Render the heading as a regular text element
                          return (
                            <>
                              <h2 key={index}>{item}</h2>
                            </>
                          );
                        }
                      }
                      return <></>
                    })
                  }
                </div>
              )}
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
      ) : (
        <div className='Article-Heading'>
          {props?.content?.attributes?.categories[0].name ? (
            <div
              className={`Issue-Container ${props.content.attributes.categories[0].name}-color`}
            >
              <p className='Issue'>ISSUE</p>
              <p className='Issue-Number'>{pad(props.content.attributes.issue)}</p>
            </div>
          ) : (
            <></>
          )}

          <div className='Image-Column-desktop-wrapper'>
            <div
              className='Image-Column-desktop'
              id='Image-Column-desktop'
              ref={imageRef}
              style={{ transform: scrollStyle!, marginTop: imageHeight! }}
            >
              {props?.content?.attributes?.media?.length > 0 ? (
                props.content.attributes.media.map(
                  (media: Image, index: number) => (
                    <div
                      className='Article-Image-Container'
                      onClick={() => setOpenLightbox(true)}
                      key={`article-img-${index}`}
                    >
                      <img
                        className='Article-Image'
                        src={ImageHandler('https://morf-prod.agileexlab.net', media, 3)}
                        onError={({ currentTarget }) => FallBackImage(currentTarget)}
                        alt=''
                        // do not change this id attribute otherwise will cause lightbox error
                        id={index.toString()}
                        onClick={(e) =>
                          setLightboxIndex(parseInt(e.currentTarget.id))
                        }
                        onLoad={() => loadImage()}
                      />
                      <p className='Article-Image-Desc'>{media.caption}</p>
                    </div>
                  )
                )
              ) : (
                <></>
              )}
            </div>
            {loadedImgRef.current === props.content.attributes.media.length ?
              <></>
              :
              <div>
                <Skeleton height={1155} width={260} />
              </div>
            }

          </div>
          <div className='Article-Title-Container'>
            <div className='Article-Title-Wrapper'>
              <div className='Article-Category-Icon-Container '>
                {props?.content?.attributes?.categories[0].name ? (
                  <img
                    className='Category-Icon'
                    src={require(`../../../../assets/img/${props.content.attributes.categories[0].name}-section-icon.png`)}
                    alt=''
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className='Article-Title'>
                {props && props.content && props.content.attributes
                  ? props.content.attributes.heading
                  : ''}
              </div>
            </div>
            {props?.content?.attributes?.categories[0].name ? (
              <hr
                className={`divider ${props.content.attributes.categories[0].name}-top-border`}
              />
            ) : (
              ''
            )}
            <div
              className='Text-Column-desktop'
              ref={ref}
              onScroll={scrollHandler}>

              {props.content.attributes.title.includes("現場直播") ? (
                <div className='Article-Text-Content'>
                  {/* <iframe src="//player.louisvuitton.com/embed/1486109" title="Louis Vuitton - Pre-Fall 2024 Men - Hong Kong - 16:9 Monobitrate" width="100%" height="300px" style={{ border: 0, left: 0, top: 0, overflow: 'auto' }} allowFullScreen></iframe> */}
                  {
                    contentArr.map((item: any, index: any) => {
                      if (index <= 6) {
                        if (index === 0) {
                          return (
                            <>
                              <ReactMarkdown
                                key={index}
                                children={item}
                                className={'React-Markdown-HTML'}
                              />
                            </>
                          )
                        }
                        if (index % 2 === 0) {
                          // Render the content as a ReactMarkdown component
                          return (
                            <ReactMarkdown
                              key={index}
                              children={item}
                              className={'React-Markdown-HTML'}
                            />
                          );
                        } else {
                          // Render the heading as a regular text element
                          return (
                            <>
                              <h2 key={index}>{item}</h2>
                            </>
                          );
                        }
                      }
                      return <></>
                    })
                  }
                  {
                    contentArr.map((item: any, index: any) => {
                      if (index > 6) {
                        if (index % 2 === 0) {
                          // Render the content as a ReactMarkdown component
                          return (
                            <ReactMarkdown
                              key={index}
                              children={item}
                              className={'React-Markdown-HTML'}
                            />
                          );
                        } else {
                          // Render the heading as a regular text element
                          return (
                            <>
                              <h2 key={index}>{item}</h2>
                            </>
                          );
                        }
                      }
                      return <></>
                    })
                  }
                </div>
              ) : (
                <div className='Article-Text-Content'>
                  {
                    contentArr.map((item: any, index: any) => {
                      if (index <= 6) {
                        if (index === 0) {
                          return (
                            <>
                              <ReactMarkdown
                                key={index}
                                children={item}
                                className={'React-Markdown-HTML'}
                              />
                            </>
                          )
                        }
                        if (index % 2 === 0) {
                          // Render the content as a ReactMarkdown component
                          return (
                            <ReactMarkdown
                              key={index}
                              children={item}
                              className={'React-Markdown-HTML'}
                            />
                          );
                        } else {
                          // Render the heading as a regular text element
                          return (
                            <>
                              <h2 key={index}>{item}</h2>
                            </>
                          );
                        }
                      }
                      return <></>
                    })
                  }
                  {
                    contentArr.map((item: any, index: any) => {
                      if (index > 6) {
                        if (index % 2 === 0) {
                          // Render the content as a ReactMarkdown component
                          return (
                            <ReactMarkdown
                              key={index}
                              children={item}
                              className={'React-Markdown-HTML'}
                            />
                          );
                        } else {
                          // Render the heading as a regular text element
                          return (
                            <>
                              <h2 key={index}>{item}</h2>
                            </>
                          );
                        }
                      }
                      return <></>
                    })
                  }
                </div>
              )}
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ArticleHeadingContainer;
