import React from 'react';
import '../Contact.css';

const ContactPageTitle = React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
  <div className='Contact-Page-Title-Div' ref={ref}>
    <h1 className='Contact-Page-Title-Chi'>聯絡</h1>
    <h1 className='Contact-Page-Title-Eng'>CONTACT</h1>
  </div>
))

export default ContactPageTitle;
