import error from '../../assets/img/error.webp'

// function that handles error when rendering images
function FallBackImage(currentTarget: any) {
  // prevents looping
  currentTarget.onerror = null;
  currentTarget.src = error;
}

export default FallBackImage;
