import React from 'react';
import { Routes, Route } from 'react-router';
import { ResultPage, AboutPage, ArticlePage, EmptyPage, NotFoundPage, PrivacyPolicyPage, LandingPage, ContactPage, SubscriptionPage, VerificationPage, ConfirmationPage, DeletePage, CookiesPolicyPage } from './pages/page-routes'

export const Rroutes = () => {
  return (
    // <Suspense>
    <Routes>
      <Route path='*' element={<NotFoundPage />} />
      <Route path='/' element={<LandingPage />} />
      <Route path='/landing' element={<LandingPage />} />
      <Route path='/about' element={<AboutPage />} />
      <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
      <Route path='/article' element={<ArticlePage />} />
      {/* <Route path='/empty' element={<EmptyPage />} /> */}

      <Route path='/' element={<LandingPage />} />
      <Route path='/about' element={<AboutPage />} />
      <Route path='/empty' element={<EmptyPage />} />
      <Route path='/article/:postId' element={<ArticlePage />} />
      <Route path='/article/' element={<ArticlePage />} />

      {/* result pages */}
      <Route path='/people' element={<ResultPage category={'people'} subcategory={''} />} />
      <Route path='/people/:pageNum' element={<ResultPage category={'people'} subcategory={''} />} />
      <Route path='/hair' element={<ResultPage category={'hair'} subcategory={''} />} />
      <Route path='/hair/:pageNum' element={<ResultPage category={'hair'} subcategory={''} />} />
      <Route path='/beauty/' element={<ResultPage category={'beauty'} subcategory={''} />} />
      <Route path='/beauty/:pageNum' element={<ResultPage category={'beauty'} subcategory={''} />} />
      <Route path='/fashion' element={<ResultPage category={'fashion'} subcategory={''} />} />
      <Route path='/fashion/:pageNum' element={<ResultPage category={'fashion'} subcategory={''} />} />
      <Route path='/mind' element={<ResultPage category={'mind'} subcategory={''} />} />
      <Route path='/mind/:pageNum' element={<ResultPage category={'mind'} subcategory={''} />} />
      <Route path='/living' element={<ResultPage category={'living'} subcategory={''} />} />
      <Route path='/living/:pageNum' element={<ResultPage category={'living'} subcategory={''} />} />
      {/* sub-category */}
      {/* Hair */}
      <Route path='/product' element={<ResultPage category={'hair'} subcategory={'product'} />} />
      <Route path='/hairstyle' element={<ResultPage category={'hair'} subcategory={'hairstyle'} />} />
      {/* Beauty */}
      <Route path='/skin-care' element={<ResultPage category={'beauty'} subcategory={'skin-care'} />} />
      <Route path='/make-up' element={<ResultPage category={'beauty'} subcategory={'make-up'} />} />
      {/* Fashion */}
      <Route path='/brand-story' element={<ResultPage category={'fashion'} subcategory={'brand-story'} />} />
      <Route path='/styling' element={<ResultPage category={'fashion'} subcategory={'styling'} />} />
      <Route path='/news' element={<ResultPage category={'fashion'} subcategory={'news'} />} />
      {/* Mind */}
      <Route path='/movie' element={<ResultPage category={'mind'} subcategory={'movie'} />} />
      <Route path='/book' element={<ResultPage category={'mind'} subcategory={'book'} />} />
      <Route path='/design' element={<ResultPage category={'mind'} subcategory={'design'} />} />
      <Route path='/art' element={<ResultPage category={'mind'} subcategory={'art'} />} />
      <Route path='/culture' element={<ResultPage category={'mind'} subcategory={'culture'} />} />
      {/* Living */}
      <Route path='/food' element={<ResultPage category={'living'} subcategory={'food'} />} />
      <Route path='/sport' element={<ResultPage category={'living'} subcategory={'sport'} />} />
      <Route path='/game' element={<ResultPage category={'living'} subcategory={'game'} />} />
      <Route path='/travel' element={<ResultPage category={'living'} subcategory={'travel'} />} />
      {/* Morf */}
      <Route path='/subscription/:status' element={<SubscriptionPage />} />
      <Route path='/subscription' element={<SubscriptionPage />} />
      <Route path='/subscription/confirm/:email' element={<ConfirmationPage />} />
      <Route path='/subscription/confirm' element={<ConfirmationPage />} />
      <Route path='/subscription/verify/:email' element={<VerificationPage />} />
      <Route path='/subscription/verify' element={<VerificationPage />} />
      <Route path='/subscription/delete/:email' element={<DeletePage />} />
      <Route path='/subscription/delete' element={<DeletePage />} />
      <Route path='/contact' element={<ContactPage />} />
      <Route path='/cookies-policy' element={<CookiesPolicyPage />} />
    </Routes>
  );
};

export default Rroutes;
