import { Footer } from '../../common/Footer';
import { Header } from '../../common/Header';
import React from 'react';
import '../../common/scss/Common.css';
import '../../common/scss/LatestSwiper.css';
import '../../common/scss/Category.css';

function EmptyPage() {
  return (
    <div className='Page-Container'>
      <Header />

      <div className='Page-Content'>
        {/* <div className='Content-Container'>
          <p>Lorem ipsum dolor sit amet. Et numquam ullam nam accusantium repellendus ut odit accusamus et obcaecati dolorem et voluptatem blanditiis et magni perspiciatis in quam velit. Nam odit reiciendis est quia corrupti et expedita ipsam ea nulla deleniti est omnis voluptas! Sit libero earum sed aspernatur nisi sit rerum voluptatem quo accusamus dolore. A quisquam eaque a nisi nisi aut doloribus reprehenderit et quibusdam vitae. </p>
          <p>Ea consequuntur ducimus At commodi enim est reiciendis consequatur ut nihil officia. Qui harum praesentium At quia vero ea assumenda optio sed necessitatibus aliquid qui voluptas repudiandae eos quod quia ut incidunt voluptatem. Sed mollitia corrupti vel dignissimos dolorum id impedit voluptatibus aut molestias similique qui accusantium nostrum vel odio sequi. </p>
          <p>At sunt voluptate ea dolorum debitis eos totam natus aut maiores autem eum consequuntur sunt! Et consequuntur molestiae qui incidunt dolorem a facilis galisum. Sit quidem officia est dolores voluptatum in sapiente facilis At odio Quis qui atque veniam aut numquam suscipit. </p>
        </div>
        <div className='Content-Container'>
          <p>Lorem ipsum dolor sit amet. Et numquam ullam nam accusantium repellendus ut odit accusamus et obcaecati dolorem et voluptatem blanditiis et magni perspiciatis in quam velit. Nam odit reiciendis est quia corrupti et expedita ipsam ea nulla deleniti est omnis voluptas! Sit libero earum sed aspernatur nisi sit rerum voluptatem quo accusamus dolore. A quisquam eaque a nisi nisi aut doloribus reprehenderit et quibusdam vitae. </p>
          <p>Ea consequuntur ducimus At commodi enim est reiciendis consequatur ut nihil officia. Qui harum praesentium At quia vero ea assumenda optio sed necessitatibus aliquid qui voluptas repudiandae eos quod quia ut incidunt voluptatem. Sed mollitia corrupti vel dignissimos dolorum id impedit voluptatibus aut molestias similique qui accusantium nostrum vel odio sequi. </p>
          <p>At sunt voluptate ea dolorum debitis eos totam natus aut maiores autem eum consequuntur sunt! Et consequuntur molestiae qui incidunt dolorem a facilis galisum. Sit quidem officia est dolores voluptatum in sapiente facilis At odio Quis qui atque veniam aut numquam suscipit. </p>
        </div>
        <div className='Content-Container'>
          <p>Lorem ipsum dolor sit amet. Et numquam ullam nam accusantium repellendus ut odit accusamus et obcaecati dolorem et voluptatem blanditiis et magni perspiciatis in quam velit. Nam odit reiciendis est quia corrupti et expedita ipsam ea nulla deleniti est omnis voluptas! Sit libero earum sed aspernatur nisi sit rerum voluptatem quo accusamus dolore. A quisquam eaque a nisi nisi aut doloribus reprehenderit et quibusdam vitae. </p>
          <p>Ea consequuntur ducimus At commodi enim est reiciendis consequatur ut nihil officia. Qui harum praesentium At quia vero ea assumenda optio sed necessitatibus aliquid qui voluptas repudiandae eos quod quia ut incidunt voluptatem. Sed mollitia corrupti vel dignissimos dolorum id impedit voluptatibus aut molestias similique qui accusantium nostrum vel odio sequi. </p>
          <p>At sunt voluptate ea dolorum debitis eos totam natus aut maiores autem eum consequuntur sunt! Et consequuntur molestiae qui incidunt dolorem a facilis galisum. Sit quidem officia est dolores voluptatum in sapiente facilis At odio Quis qui atque veniam aut numquam suscipit. </p>
        </div>
        <div className='Content-Container'>
          <p>Lorem ipsum dolor sit amet. Et numquam ullam nam accusantium repellendus ut odit accusamus et obcaecati dolorem et voluptatem blanditiis et magni perspiciatis in quam velit. Nam odit reiciendis est quia corrupti et expedita ipsam ea nulla deleniti est omnis voluptas! Sit libero earum sed aspernatur nisi sit rerum voluptatem quo accusamus dolore. A quisquam eaque a nisi nisi aut doloribus reprehenderit et quibusdam vitae. </p>
          <p>Ea consequuntur ducimus At commodi enim est reiciendis consequatur ut nihil officia. Qui harum praesentium At quia vero ea assumenda optio sed necessitatibus aliquid qui voluptas repudiandae eos quod quia ut incidunt voluptatem. Sed mollitia corrupti vel dignissimos dolorum id impedit voluptatibus aut molestias similique qui accusantium nostrum vel odio sequi. </p>
          <p>At sunt voluptate ea dolorum debitis eos totam natus aut maiores autem eum consequuntur sunt! Et consequuntur molestiae qui incidunt dolorem a facilis galisum. Sit quidem officia est dolores voluptatum in sapiente facilis At odio Quis qui atque veniam aut numquam suscipit. </p>
        </div>
        <div className='Content-Container'>
          <p>Lorem ipsum dolor sit amet. Et numquam ullam nam accusantium repellendus ut odit accusamus et obcaecati dolorem et voluptatem blanditiis et magni perspiciatis in quam velit. Nam odit reiciendis est quia corrupti et expedita ipsam ea nulla deleniti est omnis voluptas! Sit libero earum sed aspernatur nisi sit rerum voluptatem quo accusamus dolore. A quisquam eaque a nisi nisi aut doloribus reprehenderit et quibusdam vitae. </p>
          <p>Ea consequuntur ducimus At commodi enim est reiciendis consequatur ut nihil officia. Qui harum praesentium At quia vero ea assumenda optio sed necessitatibus aliquid qui voluptas repudiandae eos quod quia ut incidunt voluptatem. Sed mollitia corrupti vel dignissimos dolorum id impedit voluptatibus aut molestias similique qui accusantium nostrum vel odio sequi. </p>
          <p>At sunt voluptate ea dolorum debitis eos totam natus aut maiores autem eum consequuntur sunt! Et consequuntur molestiae qui incidunt dolorem a facilis galisum. Sit quidem officia est dolores voluptatum in sapiente facilis At odio Quis qui atque veniam aut numquam suscipit. </p>
        </div>
        <div className='Content-Container'>
          <p>Lorem ipsum dolor sit amet. Et numquam ullam nam accusantium repellendus ut odit accusamus et obcaecati dolorem et voluptatem blanditiis et magni perspiciatis in quam velit. Nam odit reiciendis est quia corrupti et expedita ipsam ea nulla deleniti est omnis voluptas! Sit libero earum sed aspernatur nisi sit rerum voluptatem quo accusamus dolore. A quisquam eaque a nisi nisi aut doloribus reprehenderit et quibusdam vitae. </p>
          <p>Ea consequuntur ducimus At commodi enim est reiciendis consequatur ut nihil officia. Qui harum praesentium At quia vero ea assumenda optio sed necessitatibus aliquid qui voluptas repudiandae eos quod quia ut incidunt voluptatem. Sed mollitia corrupti vel dignissimos dolorum id impedit voluptatibus aut molestias similique qui accusantium nostrum vel odio sequi. </p>
          <p>At sunt voluptate ea dolorum debitis eos totam natus aut maiores autem eum consequuntur sunt! Et consequuntur molestiae qui incidunt dolorem a facilis galisum. Sit quidem officia est dolores voluptatum in sapiente facilis At odio Quis qui atque veniam aut numquam suscipit. </p>
        </div>
        <div className='Content-Container'>
          <p>Lorem ipsum dolor sit amet. Et numquam ullam nam accusantium repellendus ut odit accusamus et obcaecati dolorem et voluptatem blanditiis et magni perspiciatis in quam velit. Nam odit reiciendis est quia corrupti et expedita ipsam ea nulla deleniti est omnis voluptas! Sit libero earum sed aspernatur nisi sit rerum voluptatem quo accusamus dolore. A quisquam eaque a nisi nisi aut doloribus reprehenderit et quibusdam vitae. </p>
          <p>Ea consequuntur ducimus At commodi enim est reiciendis consequatur ut nihil officia. Qui harum praesentium At quia vero ea assumenda optio sed necessitatibus aliquid qui voluptas repudiandae eos quod quia ut incidunt voluptatem. Sed mollitia corrupti vel dignissimos dolorum id impedit voluptatibus aut molestias similique qui accusantium nostrum vel odio sequi. </p>
          <p>At sunt voluptate ea dolorum debitis eos totam natus aut maiores autem eum consequuntur sunt! Et consequuntur molestiae qui incidunt dolorem a facilis galisum. Sit quidem officia est dolores voluptatum in sapiente facilis At odio Quis qui atque veniam aut numquam suscipit. </p>
        </div> */}

        <div className='Content-Container'>
          {/* <p>dummy1</p> */}
          {/* <p>dummy2</p> */}
          {/* <p>dummy3</p> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default EmptyPage;
