import React from 'react';
import '../About.css';

const AboutEng = React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
  <div className='About-Eng-Div' ref={ref}>
    <div className='About-Eng-Slogan-Title'>
      FROM NOW ON
    </div>

    <div className='About-Eng-Div-Desc'>
      <div className=''>
        A glance – for a possibility unprecedented.<br />
        A thought – for a future beyond imagination.<br />
      </div>

      <div className=''>
        We present to you styling goals and instructive contents.<br />
        Together we move on, and embrace the better self.<br />
      </div>

      <div className=''>
        Here and now,<br />
        Charmer we lock;<br />
        Brighter we shine;<br />
        Better we live<br />
      </div>

      <div className='About-Slogan morf-color'>
        MORF, FROM NOW ON.
      </div>
    </div>

    <img
      className='About-Hash Only-Mobile'
      src={require(`../../../../assets/img/about-orange-hashes.png`)}
      alt='About-Hash'
    />
  </div>
))

export default AboutEng;
