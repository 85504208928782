import './component/Footer.css'
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import '../scss/Common.css'
import '../scss/LatestSwiper.css';
import '../scss/Category.css';

function Footer() {
  const footerTitles = ['People', 'Fashion', 'Beauty', 'Mind', 'Hair', 'Living', 'MORF'];
  const footerSubtitles = [
    [],
    ['Brand Story', 'Styling', 'News'],
    ['Skin-care', 'Make-up'],
    ['Movie', 'Book', 'Design', 'Art', 'Culture'],
    ['Product', 'Hairstyle'],
    ['Food', 'Sport', 'Game', 'Travel'],
    ['About', 'Subscription', 'Contact', 'Privacy Policy', 'Cookies Policy']
  ];
  const navigate = useNavigate();

  return (
    <div className='Footer-Container' id='Footer'>
      <div className='Footer-Content Content-Container'>
        <Grid container spacing={0} className='Grid-Container'>
          {footerTitles.map((footerTitle, index) =>
            <Grid item xs className='Grid-Item' key={`footer-col-${index}`}>
              <div className='Col'>
                <Link className={`Footer-Title ${footerTitle.toLowerCase()}-color`}
                  onClick={() => navigate(`/${footerTitle.replace(/\s+/g, '-').toLowerCase()}`)}
                  underline='none' color='initial' style={{ cursor: 'pointer' }}>
                  {footerTitle}
                </Link>
                {footerSubtitles[index].map((footerSubtitle, index) =>
                  <Link className='Footer-Subtitle' key={`footer-col-element-${index}`}
                    onClick={() => navigate(`/${footerSubtitle.replace(/\s+/g, '-').toLowerCase()}`)}
                    underline='none' color='initial' style={{ cursor: 'pointer' }}>
                    {footerSubtitle}
                  </Link>
                )}
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default Footer;
