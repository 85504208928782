import React from 'react';
import '../About.css';

const AboutPageTitle = React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
  <div className='About-Page-Title-Div' ref={ref}>
    <h1 className='About-Page-Title-Chi'>關於</h1>
    <h1 className='About-Page-Title-Eng'>About</h1>
  </div>
))

export default AboutPageTitle;
