import { useEffect, useState, useContext } from 'react';
import axios, { AxiosResponse } from 'axios';
import { Latest } from '../Latest';
import { LoadingContext } from '../../../Loading/component/Context';
// type and init
import { PostRequestBody } from '../../../type/type';
import {
  filterResult,
  handleResponseData
} from '../../Strapi'

export default function Post(request: PostRequestBody) {
  const { loadingFinish } = useContext(LoadingContext);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const urlParam =
    'populate[0]=categories&' +
    'populate[1]=desktop_169&' +
    'populate[2]=mobile_916&' +
    'populate[3]=square_89&' +
    'populate[4]=sub_categories&' +
    'fields[0]=description&' +
    'fields[1]=title&' +
    'pagination[limit]=-1'
  const url =
    'https://morf-prod.agileexlab.net/api/posts?' + urlParam; // get full raw data from API, including relations and media list
  const authentication = {
    headers: {
      Authorization:
        'Bearer ' +
        '436b7d7cd0b85ff27e2dde37c7255b0dd7c1791ed102543b6e2dacb57ec69eec708496ee2fd051a81b58a86dd1b44081e64e5cc3461b99740a3ce9ed2aa4bfbbb9f96b4db2197df862fc26cb8332693ea515179a287fd6f94f97628574af396ba4040b52e08d15a6adb4e401858c650d7ed508975f978b11cb210a76053554fe',
    },
  };
  const [fetchedData, setFetchedData] = useState<AxiosResponse | null>(null);
  useEffect(() => {
    const getData = async () => {
      const data = await axios
        .get(url, authentication)
        .then((res) => {
          setFetchedData(res);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
          loadingFinish();
        });
    };
    getData();
  }, []);
  const postList = handleResponseData(fetchedData);
  if (request.category === 'latest') {
    return Latest(postList, request.numberOfRecord);
  } else {
    return filterResult(postList, request);
  }
}
