import '../ResultPageLayout/ResultPageLayout.css';
import './ResultPagePagination.css';
import { Link } from 'react-router-dom';
import React from 'react';
interface Props {
  category: string;
  totalPages: number;
  activePage: number;
}

const ResultPagePagination: React.FC<Props> = (props) => {
  // pageArr is an array that contains 1 to {lastPage} of a category result
  let pageArr = []
  for (var i = 1; i <= props.totalPages; i++) {
    pageArr.push(i)
  }

  return (
    <div className={`resultPaginationContainer`}>
      {pageArr.map((targetPage: any, index: number) => (
        // https://v5.reactrouter.com/web/example/url-params
        <Link
          key={`pagination-${index}`}
          to={`/${props.category}/${targetPage}`}
          className={`resultPaginationEl ${props.category}-color ${targetPage === props.activePage ? 'activeResultPage' : ''}`}>
          {targetPage}
        </Link>
      ))}
    </div>
  );
};

export default ResultPagePagination;
