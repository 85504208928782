import { createSlice } from "@reduxjs/toolkit";

export interface HashBgState {
  category: 'people' | 'hair' | 'beauty' | 'fashion' | 'mind' | 'living';
  currentSlide: number;
}

const initialState: HashBgState = {
  category: 'people',
  currentSlide: 0
};

// slice that contains the current page/post category
export const hashBgSlice = createSlice({
  name: "hashBg",
  initialState,
  reducers: {
    setCategory: (state, data) => {
      state.category = data.payload
    }
  },
});

export const { setCategory } = hashBgSlice.actions;

export default hashBgSlice.reducer;