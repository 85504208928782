import './component/ImageGroup.css'
import React from 'react';
import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import '../scss/Category.css';
import { useNavigate } from 'react-router-dom';
import { FallBackImage } from '../FallBackImage';

interface Props {
  large: string;
  img1: string;
  img2: string;
  img3: string;
  id: number;
}
// image group of 3 for different category sections on the landing page (mobile)
const ImageGroup: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  return (
    <>
      {/* big img on left */}
      {props.large === 'left' &&
        <div className='Image-Wrapper' onClick={() => navigate(`/article/${props.id}`)}>
          <img src={props.img1} className='Main-Image' alt='Main' onError={({ currentTarget }) => FallBackImage(currentTarget)} />
          <div className='Inner-Wrapper'>
            <img src={props.img2} className='Inner-Image' alt='Inner' onError={({ currentTarget }) => FallBackImage(currentTarget)} />
            <img src={props.img3} className='Inner-Image' alt='Inner' onError={({ currentTarget }) => FallBackImage(currentTarget)} />
          </div>
        </div>
      }
      {/* big img on right */}
      {props.large === 'right' &&
        <div className='Image-Wrapper' onClick={() => navigate(`/article/${props.id}`)}>
          <div className='Inner-Wrapper'>
            <img src={props.img2} className='Inner-Image' alt='Inner' onError={({ currentTarget }) => FallBackImage(currentTarget)} />
            <img src={props.img3} className='Inner-Image' alt='Inner' onError={({ currentTarget }) => FallBackImage(currentTarget)} />
          </div>
          <img src={props.img1} className='Main-Image' alt='Main' onError={({ currentTarget }) => FallBackImage(currentTarget)} />
        </div>
      }
    </>
  );
}

export default ImageGroup;
