import React from 'react';
import '../Contact.css';

const ContactInfo = React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
  <div className='Contact-Info-Div' ref={ref}>
    <div className='Contact-Info'>
      media@morf.today
    </div>
    <br /><br />
    <div className='Contact-Info-Address'>
      Horizon East,<br />
      No. 1 Tsat Po Street,<br />
      San Po Kong,<br />
      Hong Kong
    </div>
    <br /><br />
    <div className='Contact-Info-Slogan morf-color'>
      MORF, FROM NOW ON.
    </div>
    <img
      className='Contact-Info-Hash'
      src={require(`../../../../assets/img/contact-hash.png`)}
      alt='Contact-Info-Hash'
    />
  </div>
))

export default ContactInfo;
