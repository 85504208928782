import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './ResultPageSkeleton.css';

const ResultPageSkeleton = () => {
  return (
    <div className='Result-Content-Container'>
      {Array(12)
        .fill(0)
        .map((article: any, index: number) => (
          <div key={`result-article-${index}`}>
            <div className='Result-Article-Container'>
              <div className='sixteen-nine'>
                <Skeleton style={{ paddingTop: '56.25%' }} />
              </div>
              <div className={'result-card-title'}>
                <Skeleton />
              </div>
            </div>
            <div>
              <p className='Result-Post-Excerpt'>
                <Skeleton count={3} />
              </p>
            </div>
            <div className='Read-More'>
              <Skeleton />
            </div>
          </div>
        ))}
    </div>
  );
};

export default ResultPageSkeleton;
