import React from 'react';
import '../About.css';

const AboutChi = React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
  <div className='About-Chi-Div' ref={ref}>
    <div className='About-Chi-Slogan-Title'>
      從此 不一樣
    </div>

    <div className='About-Chi-Div-Desc'>
      <div className=''>
        一瞥，看見你未曾嘗試的可能；<br />
        回想，塑造你不再一樣的未來。<br />
      </div>

      <div className=''>
        MORF致力為你的目標提供引導和方向，<br />
        以與別不同的角度送上造型資訊，<br />
        讓你思考如何為自己作出改變。<br />
      </div>

      <div className=''>
        此處，活得不一樣。<br />
        此刻，顯得不一樣。<br />
        此人，變得不一樣。<br />
      </div>

      <div className='About-Slogan'>
        MORF, FROM NOW ON.
      </div>
    </div>

    <img
      className='About-Hash'
      src={require(`../../../../assets/img/about-black-hashes.png`)}
      alt='About-Hash'
    />

    <img
      className='About-Hash Only-Mobile'
      src={require(`../../../../assets/img/about-white-hashes.png`)}
      alt='About-Hash'
    />
  </div>
))

export default AboutChi;
