import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper';
import { useNavigate } from 'react-router-dom';
import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import '../scss/Category.css';

import { Today } from '../Today';
import { ReadMore } from '../ReadMore';
import { PostBody } from '../type/type';
import { ImageHandler } from '../ImageHandler';
import { FallBackImage } from '../FallBackImage';

interface Props {
  latestPosts?: PostBody[];
  body: PostBody[];
}

// swiper that shows the latest 10 posts on landing page
const LatestSwiper: React.FC<Props> = (props: Props) => {
  const [imageLoadState, setImageLoadState] = useState(true);
  const latestPosts = props.body.slice(0, 10);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1024);
  };

  const latestPostsCategories =
    latestPosts && latestPosts.map((post) => post.attributes.categories[0].name);

  const navigate = useNavigate();
  // initialize hash background
  useEffect(() => {
    if (latestPosts.length) {
      const currentCategoryUpper =
        latestPostsCategories[0].charAt(0).toUpperCase() +
        latestPostsCategories[0].slice(1);
      const currentHash = document.getElementsByClassName(
        currentCategoryUpper + '-Hash'
      )[0] as HTMLElement;
      if (currentHash) {
        currentHash.style.opacity = '100%';
      }
    }

    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, [latestPosts.length, latestPostsCategories]);

  const updateBackground = (activeIndex: number) => {
    // handle swiper js weird indexing
    activeIndex -= 1;
    // hardcoding exceptional cases.
    if (activeIndex === 10) {
      activeIndex = 0;
    }
    if (activeIndex === -1) {
      activeIndex = 9;
    }

    if (latestPosts.length) {
      // remove prev (all) hash bg
      const peopleHash = document.getElementsByClassName(
        'People-Hash'
      )[0] as HTMLElement;
      const hairHash = document.getElementsByClassName(
        'Hair-Hash'
      )[0] as HTMLElement;
      const beautyHash = document.getElementsByClassName(
        'Beauty-Hash'
      )[0] as HTMLElement;
      const fashionHash = document.getElementsByClassName(
        'Fashion-Hash'
      )[0] as HTMLElement;
      const mindHash = document.getElementsByClassName(
        'Mind-Hash'
      )[0] as HTMLElement;
      const livingHash = document.getElementsByClassName(
        'Living-Hash'
      )[0] as HTMLElement;
      peopleHash.style.opacity = '0%';
      hairHash.style.opacity = '0%';
      beautyHash.style.opacity = '0%';
      fashionHash.style.opacity = '0%';
      mindHash.style.opacity = '0%';
      livingHash.style.opacity = '0%';

      // add current hash bg
      const currentCategoryUpper =
        latestPostsCategories[activeIndex].charAt(0).toUpperCase() +
        latestPostsCategories[activeIndex].slice(1);
      const currentHash = document.getElementsByClassName(
        currentCategoryUpper + '-Hash'
      )[0] as HTMLElement;
      currentHash.style.opacity = '100%';
    }
  };

  const initBackground = (swiper: any) => {
    swiper.slideTo(1);
  };

  useEffect(() => {
    if (document.getElementById('Latest-Swiper-Title-0') !== null) {
      for (let i = 0; i < 10; i++) {
        let titleEl = document.getElementById(`Latest-Swiper-Title-${i}`)!;
        let titleElPadding = parseInt(window.getComputedStyle(titleEl).getPropertyValue('padding-top').slice(0, -2)) + parseInt(window.getComputedStyle(titleEl).getPropertyValue('padding-bottom').slice(0, -2));
        let titleElHeight = titleEl.offsetHeight - titleElPadding
        var titleLineHeightInPx = parseInt(window.getComputedStyle(titleEl).getPropertyValue('line-height').slice(0, -2));
        let lines = Math.floor(titleElHeight / titleLineHeightInPx);
        if (lines === 2) {
          document.getElementById(`Latest-Swiper-Bar-${i}`)!.style.height = '24%';
        }
      }
    }
  });

  return (
    <div className='Latest-Swiper Content-Container'>
      {/* <div style={{height:0,paddingBottom:'56.250%',position:'relative'}}/>
      <iframe src="//player.louisvuitton.com/embed/1486109" title="Louis Vuitton - Pre-Fall 2024 Men - Hong Kong - 16:9 Monobitrate" width="100%" height="100%" style={{border:0,left:0,position:'absolute',top:0,}}></iframe> */}
      {/* <Spinner /> */}
      <Swiper
        loop={true}
        id='Latest-Swiper'
        className='My-Swiper'
        observer={true}
        uniqueNavElements={false}
        onAfterInit={(swiper) => initBackground(swiper)}
        onActiveIndexChange={({ activeIndex }) => updateBackground(activeIndex)}
        modules={[Navigation, Pagination]}
        pagination={{
          enabled: true,
          el: '.Latest-Swiper-Custom-Pagination',
          renderBullet: (index, className) => {
            return '<span class="' + className + '"></span>';
          },
        }}
        navigation={{
          enabled: true,
        }}
      >
        {latestPosts.length ? (
          latestPosts.map((latestPosts, index) => (
            <SwiperSlide
              className={
                latestPosts && latestPosts.attributes.categories[0].name
              }
              key={`latest-slide-${latestPosts.id}`}
            >
              <div className='Latest-Swiper-Title-Wrapper'>
                {/* Today's date and post title (mobile) */}
                <Today category={latestPosts.attributes.categories[0].name} />
                <p className='Latest-Swiper-Title Latest-Swiper-Title-Mobile'>
                  {latestPosts.attributes.title}
                </p>
              </div>

              <div
                className='Latest-Swiper-Image-Wrapper'
                onClick={() => navigate(`/article/${latestPosts.id}`)}
              >
                {/* Post image */}

                {imageLoadState && (
                  <Skeleton
                    style={{
                      position: 'absolute',
                      height: 'calc(920px * 0.85 * 0.5625)',
                      zIndex: '0',
                    }}
                    duration={1}
                  />
                )}
                {isDesktop ?
                  // desktop images
                  <img
                    className='Latest-Swiper-Image'
                    src={ImageHandler('https://morf-prod.agileexlab.net', latestPosts.attributes.desktop_169[0], 2)}
                    alt=''
                    onLoad={(event) => {
                      event.type === 'load'
                      ? setImageLoadState(false)
                      : setImageLoadState(true);
                    }}
                    onError={({ currentTarget }) => FallBackImage(currentTarget)}
                    /> :
                  // mobile images
                  <img
                    className='Latest-Swiper-Image'
                    src={ImageHandler('https://morf-prod.agileexlab.net', latestPosts.attributes.mobile_916[0], 2)}
                    alt=''
                    onLoad={(event) => {
                      event.type === 'load'
                        ? setImageLoadState(false)
                        : setImageLoadState(true);
                    }}
                    onError={({ currentTarget }) => FallBackImage(currentTarget)}
                  />}

                {/* Colored bar behind the title */}
                <img
                  className='Bottom-Left Latest-Swiper-Image-Bar'
                  src={require(`../../assets/img/${latestPosts.attributes.categories[0].name}-bar.png`)}
                  alt=''
                  id={`Latest-Swiper-Bar-${index}`}
                />
                {/* Post title (desktop) */}
                <p className='Bottom-Left Latest-Swiper-Title Latest-Swiper-Image-Title-PC' id={`Latest-Swiper-Title-${index}`}>
                  {latestPosts.attributes.title}
                </p>
              </div>

              <div className='Latest-Swiper-Desc-Wrapper'>
                {/* Colored hash (mobile) */}
                <div className='Latest-Swiper-Category-Img-Mobile-Wrapper'>
                  <img
                    className='Latest-Swiper-Category-Img-Mobile'
                    src={require(`../../assets/img/${latestPosts.attributes.categories[0].name}-hash.png`)}
                    alt=''
                  />
                </div>
                {/* Desc */}
                <div className='Latest-Excerpt-Readmore'>
                  <div className='Latest-Excerpt First-Four-Lines'>
                    {latestPosts.attributes.description}
                  </div>
                  <ReadMore
                    category={latestPosts.attributes.categories[0].name}
                    id={latestPosts.id}
                    use={'landing'}
                  />
                </div>
              </div>
            </SwiperSlide>
          ))
        ) : (
          <></>
        )}
      </Swiper>
      <div className='Latest-Swiper-Custom-Pagination' />
    </div >
  );
};

export default LatestSwiper;
