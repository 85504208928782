import React from 'react';
import { ReadMore } from "../ReadMore";
import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import '../scss/Category.css';
import { useNavigate } from 'react-router-dom';

interface Props {
  category: string;
  postTitle: string;
  postDesc: string;
  id: number;
}
// desc area for different category sections
const CategoryExcerptReadmore: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  return (
    <div className='Category-Excerpt-Readmore' >
      <div className={`Category-Title Title-Font ${props.category}-color`} onClick={() => navigate(`/article/${props.id}`)}>
        {props.postTitle}
      </div>
      <div className='Category-Excerpt-Desc First-Four-Lines'>
        {props.postDesc}
      </div>
      <ReadMore category={props.category} id={props.id} use={'landing'} />
    </div>
  );
}

export default CategoryExcerptReadmore;
