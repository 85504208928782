/*
  Request body for calling API Post() from Strapi Backend
    category: post category, all -> all category, latest -> latest nth post
    numberOfRecord: number of record filtered, -1 -> all
*/
export interface PostRequestBody {
  category: string // latest, all, ["people", "hair", "beauty", "fashion", "mind", "living", "morf"]
  numberOfRecord: number // -1, 1,2,3,4,5...
}

export function initPostRequestBody(): PostRequestBody {
  return {
    category: "all",
    numberOfRecord: -1
  }
}

/* 
  Post API Reference type
  Special:  Media -> list of Image interface with different size of Image content
            Category -> Includes name of the category -> cat/subcat
*/

// Single post type: Used by all other Components
export interface PostBody {
  id: number
  attributes: PostAttribute
}

export function initPostBody(): PostBody {
  return {
    id: 0,
    attributes: initPostAttribute()
  }
}

export interface PostAttribute {
  content: string
  desktop_169: Image[]
  // square_89: Image[]
  // mobile_916: Image[]
  square_89: any
  mobile_916: any
  createdAt: string
  description: string
  heading: string
  publishedAt: string
  title: string
  updatedAt: string
  media: Image[]
  categories: Category[]
  subCategories: Category[]
  video: string
  issue: number
}

export function initPostAttribute(): PostAttribute {
  return {
    content: "",
    desktop_169: [initImage()],
    square_89: [initImage()],
    mobile_916: [initImage()],
    createdAt: "",
    description: "",
    heading: "",
    publishedAt: "",
    title: "",
    updatedAt: "",
    media: [initImage()],
    categories: [initCategory()],
    subCategories: [initCategory()],
    video: "",
    issue: 0
  }
}

export interface Image {
  id: number
  name: string
  caption: string
  alternativeText: string
  normal: ImageContent // including url w h
  small: ImageContent
  large: ImageContent
  medium: ImageContent
  thumbnail: ImageContent
}

export function initImage(): Image {
  return {
    id: 0,
    name: "",
    caption: "",
    alternativeText: "",
    normal: initImageContent(),
    small: initImageContent(),
    large: initImageContent(),
    medium: initImageContent(),
    thumbnail: initImageContent()
  }
}

export interface ImageContent {
  name: string
  url: string
  height: number
  width: number
}

export function initImageContent(): ImageContent {
  return {
    name: "",
    url: "",
    height: 0,
    width: 0
  }
}

export interface Category {
  name: string
  createdAt: string
  updatedAt: string
  publishedAt: string
}

export function initCategory() {
  return {
    name: "",
    createdAt: "",
    updatedAt: "",
    publishedAt: ""
  }
}

export interface EmailDetail {
  receive: string
  content: string
  success: boolean
}

export interface EmailAttribute {
  data: EmailRequestBody
}

export interface EmailRequestBody {
  email: string
}