import './ResultPageLayout.css';
import { Card } from '../../../../common/Card';
import { ReadMore } from '../../../../common/ReadMore';
import React, { useContext, useEffect } from 'react';
import { PostRequestBody } from '../../../../common/type/type';
import { LoadingContext } from '../../../../common/Loading/component/Context';
import { ResultPageSkeleton } from '../ResultPageSkeleton';
import { Post } from '../../../../common/Strapi';
import { filterResultBySubcategory } from '../../../../common/Strapi/Strapi';
import { useParams } from 'react-router';
import { ResultPagePagination } from '../ResultPagePagination';
import { ImageHandler } from '../../../../common/ImageHandler';

interface Props {
  category: string;
  subcategory: string;
}

const ResultPageLayout: React.FC<Props> = (props) => {
  const param = useParams();

  const postPerPage = 12;
  const currentPage: number = param.pageNum ? parseInt(param.pageNum) : 1;
  // currentPosts is an array that contains all posts that are shown on current page
  let currentPosts: any = [];

  const { loadingBool } = useContext(LoadingContext);
  const requestBody: PostRequestBody = {
    category: props.category,
    numberOfRecord: -1,
  };
  let data: any;
  if (props.subcategory === '') {
    data = Post(requestBody);
  } else {
    data = filterResultBySubcategory(Post(requestBody), props.subcategory);
  }

  // totalPages is the number of pages a category has
  const totalPages = Math.ceil(data.length / 12);

  if (data) {
    currentPosts = data
      .reverse()
      .slice((currentPage - 1) * postPerPage, postPerPage * currentPage);
  }
  useEffect(() => {
    let titleCategory =
      props.subcategory !== '' ? props.subcategory : props.category;
    titleCategory = (
      titleCategory.charAt(0).toUpperCase() + titleCategory.slice(1)
    ).replace('-', ' ');

    document.title = `${titleCategory}${currentPage > 1 ? ` – Page ${currentPage}` : ''
      } – MORF, FROM now on`;
  }, [currentPage, props.category, props.subcategory]);

  return (
    <>
      {loadingBool ? (
        <ResultPageSkeleton />
      ) : (
        <>
          <div className='Result-Content-Container'>
            {currentPosts.length === 0 || currentPage > totalPages ? (
              // dummy spacing div to make spacing for hash bg when theres not enough posts for a category
              <div className='Hash-Bg-Container' />
            ) : (
              currentPosts.map((article: any, index: number) => (
                <div
                  className='Result-Article-Container'
                  key={`result-article-${index}`}
                >
                  {/* Result post component here */}
                  <Card
                    use={'result'}
                    first={false}
                    category={props.category}
                    imgSrc={
                      ImageHandler('https://morf-prod.agileexlab.net', article.attributes?.desktop_169[0], 4)
                    }
                    title={article.attributes.title}
                    link={'/article'}
                    id={article.id}
                  />
                  <div>
                    <p className='Result-Post-Excerpt First-Four-Lines'>
                      {article.attributes.description}
                    </p>
                  </div>
                  <ReadMore category={props.category} id={article.id} use={'result'} />
                </div>
              ))
            )}
          </div>
          {totalPages > 1 && !(currentPage > totalPages) ? (
            <ResultPagePagination
              category={props.category}
              totalPages={totalPages}
              activePage={currentPage}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default ResultPageLayout;
