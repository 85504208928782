import React from "react";
import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import '../scss/Category.css';

interface Props {
  category: string;
}

// component that shows today's date
const Today: React.FC<Props> = (props: Props) => {
  // get current day and month
  let day = new Date().toUTCString().slice(4, -21).replace(/ /g, "");
  let month = new Date().toUTCString().slice(-21, -18).replace(/ /g, "");

  return (
    <div className={`Today ${props.category}-color`}>
      <div className="Day">{day}</div>
      <div className="Month">{month}</div>
    </div>
  );
};

export default Today;
