import React from "react";
import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import '../scss/Category.css';

interface Props {
  category: string;
}

// category icon and border on top of every landing page category section (desktop)
const CategoryHeader: React.FC<Props> = (props: Props) => {
  return (
    <div className='Category-Section-Icon Section-Bottom-Border'>
      <img className='Category-Icon Section-Bottom-Border Section-Right-Border'
        src={require(`../../assets/img/${props.category}-section-icon.png`)} alt='' />
    </div>
  );
}

export default CategoryHeader;
