/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import './component/TopButton.css';
import { useLocation } from 'react-router-dom';

interface Props {
  category: string;
}

const TopButton: React.FC<Props> = (props: Props) => {
  // const [footerShown, setFooterShown] = useState(false);
  const [hideTopButton, setHideTopButton] = useState(false);
  const location = useLocation();

  // let footer;

  // window.addEventListener(
  //   'load',
  //   (event) => {
  //     footer = document.querySelector<Element>('.Footer-Container')!;
  //     let options = {
  //       root: null,
  //       rootMargin: '0px',
  //       threshold: 0.5,
  //     };

  //     const handleIntersect = (entry: any) => {
  //       if (entry[0].isIntersecting) {
  //         setFooterShown(true);
  //       } else {
  //         setFooterShown(false);
  //       }
  //     };

  //     var observer = new IntersectionObserver(handleIntersect, options);

  //     observer.observe(footer);
  //   },
  //   false
  // );

  const topButtonVisible = () => {
    // hide button if scrolled to the bottom or page is not scrollable (page height == device height)
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight || window.screen.height === document.body.scrollHeight) {
      setHideTopButton(true);
    } else {
      setHideTopButton(false);
    }
  }

  // update value of hideTopButton onLoad, onScroll, and onNavigate
  useEffect(() => {
    topButtonVisible()
  }, [location]);

  window.addEventListener('load', topButtonVisible);
  window.addEventListener('scroll', topButtonVisible);

  const clickHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div id='Top-Button'>
      <img
        className='topButton'
        src={require(`../../assets/img/${props.category === '' || props.category === undefined ? 'morf' : props.category}-top.png`)}
        onClick={clickHandler}
        style={{
          // visibility: !footerShown ? 'visible' : 'hidden',
          // opacity: !footerShown ? 1 : 0,
          visibility: hideTopButton ? 'hidden' : 'visible',
          opacity: hideTopButton ? 0 : 1,

        }}
      />
    </div>
  );
}
export default TopButton;
