import React from 'react';
import { useEffect } from 'react';
import '../subscription.css'
import { useEmail } from '../../../../common/Strapi';
import { useParams } from 'react-router';

const Newsletter = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
  const { createEmail } = useEmail();
  function afterSubmission(event: any) {
    event.preventDefault();
    createEmail({ email: `${(document.getElementById('email') as HTMLInputElement).value}` });
  }
  const status = useParams();
  const statusMessage =
    (typeof status.status === "undefined") ? ""
      : status.status === "success" ? 'Success! An email was just sent to confirm your subscription. Please find the email now and click Confirm Follow to start subscribing.'
        : status.status === "fail" ? 'There was an error when subscribing. Please try again'
          : 'You have already subscribed to this site. Please check your inbox. Contact us for more information!'
  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        const submitbutton = document.getElementById('submitbutton') as HTMLElement;
        submitbutton.click();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);
  const display = status.status === "success" ? 'none' : 'block';
  return (
    <div className='Newsletter-Div' ref={ref}>
      <div className='Newsletter'>
        <p className='Newslettertitle'>Newsletter</p>
        <br />
        <p className='Newslettertext'>Join our newsletter below to stay up to date about Morf information and exclusive specials!</p>
        <div className='NewsletterinputStatus'>{statusMessage}</div>
        <form id="myform" onSubmit={afterSubmission} style={{ display: display }}>
          <input placeholder="Email" className='Newsletterinput' type="email" id="email" name="email"></input>
          <input type="submit" id="submitbutton" />
        </form>
      </div>
    </div>
  );
}
)
export default Newsletter;
