import { Header } from '../../common/Header';
import { Footer } from '../../common/Footer';
import { ResultPageLayout } from './component/ResultPageLayout';
import { setCategory } from '../../common/Reducers/HashBgSlice';
import type { RootState } from '../../common/Reducers/store';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { HashBg } from '../../common/HashBg';
import '../../common/scss/Common.css';
import '../../common/scss/LatestSwiper.css';
import '../../common/scss/Category.css';

import { LoadingProvider } from '../../common/Loading/component/Provider';
import { TopButton } from '../../common/TopButton';
interface Props {
  category: string;
  subcategory: string;
}

const ResultPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const hashBgDrawer = useSelector((state: RootState) => state.hashBg);
  dispatch(setCategory(props.category));

  useEffect(() => {
    if (typeof props.category === 'string') {
      // remove prev (all) hash bg
      const peopleHash = document.getElementsByClassName(
        'People-Hash'
      )[0] as HTMLElement;
      const hairHash = document.getElementsByClassName(
        'Hair-Hash'
      )[0] as HTMLElement;
      const beautyHash = document.getElementsByClassName(
        'Beauty-Hash'
      )[0] as HTMLElement;
      const fashionHash = document.getElementsByClassName(
        'Fashion-Hash'
      )[0] as HTMLElement;
      const mindHash = document.getElementsByClassName(
        'Mind-Hash'
      )[0] as HTMLElement;
      const livingHash = document.getElementsByClassName(
        'Living-Hash'
      )[0] as HTMLElement;
      peopleHash.style.opacity = '0%';
      hairHash.style.opacity = '0%';
      beautyHash.style.opacity = '0%';
      fashionHash.style.opacity = '0%';
      mindHash.style.opacity = '0%';
      livingHash.style.opacity = '0%';

      const currentCategoryUpper =
        props.category.charAt(0).toUpperCase() + props.category.slice(1);
      const currentHash = document.getElementsByClassName(
        currentCategoryUpper + '-Hash'
      )[0] as HTMLElement;
      currentHash.style.opacity = '100%';
    }
  }, [props.category]);


  return (
    <div className='Page-Container'>
      <Header currentPage={props.category} />
      <LoadingProvider>
        <HashBg category={hashBgDrawer.category} />
        <div className='Page-Content'>
          <div className='Content-Container'>
            {/* <div className='Ads-Container'>
              <div className='Ads-Left'>
                <Advertisement></Advertisement>
              </div>
              <div className='Ads-Right'>
                <Advertisement></Advertisement>
              </div>
            </div> */}
            <ResultPageLayout
              category={props.category}
              subcategory={props.subcategory}
            />
          </div>
          <TopButton category={props.category} />
        </div>
      </LoadingProvider>
      <Footer />
    </div>
  );
};

export default ResultPage;
