import axios from 'axios';
import { EmailRequestBody } from '../../../type/type';
// type and init

export const useEmail = () => {
  const url =
    'https://morf-prod.agileexlab.net/api/subscriptions'; // get full raw data from API, including relations and media list
  const authentication = {
    headers: {
      Authorization:
        'Bearer ' +
        '436b7d7cd0b85ff27e2dde37c7255b0dd7c1791ed102543b6e2dacb57ec69eec708496ee2fd051a81b58a86dd1b44081e64e5cc3461b99740a3ce9ed2aa4bfbbb9f96b4db2197df862fc26cb8332693ea515179a287fd6f94f97628574af396ba4040b52e08d15a6adb4e401858c650d7ed508975f978b11cb210a76053554fe',
    },
  };
  const createEmail = async (request: EmailRequestBody) => {
    var result = {}
    if (request.email === "") {
    } else {
      const data = await axios
        .post(url, { data: request }, authentication)
        .then((res) => {
          result = res
        })
        .catch((err) => {
          console.log(err);
          window.location.href = `/subscription/fail`;
        })
        .finally(() => {
          window.location.href = `/subscription/success`;
        });
    }
  }
  const verifyEmail = async (request: EmailRequestBody) => {
    var emailId = 0;
    if (request.email === "") {
    } else {
      const data = await axios
        .get(url, authentication)
        .then((res) => {
          for (const email of res?.data?.data) {
            // console.log(email.attributes.email)
            if (email.attributes.email === request.email) {
              emailId = email.id;
            }
          }
          console.log(res)
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
        });
    }
    if (emailId !== 0) {
      console.log("PUT")
      const data = await axios
        .put(url + '/' + emailId.toString(), { data: { email: request.email, verified: true } }, authentication)
        .then((res) => {
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          window.location.href = `/subscription/confirm/${request.email}`;
        });
    }
  }
  const deleteEmail = async (request: EmailRequestBody) => {
    var emailId = 0;
    if (request.email === "") {
    } else {
      const data = await axios
        .get(url, authentication)
        .then((res) => {
          for (const email of res?.data?.data) {
            if (email.attributes.email === request.email) {
              emailId = email.id;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
        });
    }
    if (emailId !== 0) {
      const data = await axios
        .delete(url + '/' + emailId.toString(), authentication)
        .then((res) => {
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          window.location.href = ``;
        });
    }
  }
  return { createEmail, verifyEmail, deleteEmail }
}