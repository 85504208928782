import React, { useEffect } from "react";
import './component/NotFoundPage.css'
import '../../common/scss/Common.css';
import '../../common/scss/LatestSwiper.css';
import '../../common/scss/Category.css';
import { Header } from '../../common/Header';
import { Footer } from '../../common/Footer';
import { TopButton } from "../../common/TopButton";

function NotFoundPage() {
  useEffect(() => {
    document.title = `Page not found – MORF, FROM now on`;
  });

  return (
    <div className="Page-Container">
      <Header />
      <div className="Page-Content">
        <div className='Content-Container'>
          <div className="Not-Found-Container">
            <div className="Not-Found-Content">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
            </div>
            <div className="Not-Found-Content">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
            </div>
          </div>
        </div>
        <TopButton category='' />
      </div>
      <Footer />
    </div>
  );
}

export default NotFoundPage;
