import React, { useEffect, useState } from 'react';
import { HashBg } from '../../../../common/HashBg';
import { LatestSwiper } from '../../../../common/LatestSwiper';
import { BeautySection } from '../beauty-section';
import { HairSection } from '../hair-section';
import { LivingSection } from '../living-section';
import '../../../../common/scss/Common.css';
import '../../../../common/scss/LatestSwiper.css';
import '../../../../common/scss/Category.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../common/Reducers/store';
import 'swiper/css';
import 'swiper/css/navigation';
import { PostBody, PostRequestBody } from '../../../../common/type/type';
import { Post, filterResult } from '../../../../common/Strapi';
import { Spinner } from '../../../../common/Spinner';
import { PeopleSection } from '../people-section';
import { FashionSection } from '../fashion-section';
import { MindSection } from '../mind-section';
import { TopButton } from '../../../../common/TopButton';

function filterByCategory(
  postResult: PostBody[],
  category: string,
  number: number
) {
  const request = {
    category: category,
    numberOfRecord: number,
  };
  return filterResult(postResult, request);
}
const LandingComponent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hashBgDrawer = useSelector((state: RootState) => state.hashBg);
  const requestBody: PostRequestBody = {
    category: 'all',
    numberOfRecord: -1,
  };
  const postResult: PostBody[] = Post(requestBody);
  const peopleResult = filterByCategory(postResult, 'people', -1).reverse();
  const hairResult = filterByCategory(postResult, 'hair', -1).reverse();
  const beautyResult = filterByCategory(postResult, 'beauty', -1).reverse();
  const fashionResult = filterByCategory(postResult, 'fashion', -1).reverse();
  const mindResult = filterByCategory(postResult, 'mind', -1).reverse();
  const livingResult = filterByCategory(postResult, 'living', -1).reverse();
  const allResult = filterByCategory(postResult, 'all', -1).reverse();
  useEffect(() => {
    document.title = 'MORF, FROM now on';
  }, []);
  return (
    <>
      <HashBg category={hashBgDrawer.category} />
      <div className='Page-Content'>
        <div className='Content-Container'>
          <Spinner />
          <LatestSwiper body={allResult} />
          <PeopleSection category='people' large='left' body={peopleResult} />
          {/* <div className='Ads-Container'>
            <div className='Ads-Left'>
              <Advertisement></Advertisement>
            </div>
            <div className='Ads-Right'>
              <Advertisement></Advertisement>
            </div>
          </div> */}
          <FashionSection category='fashion' large='right' body={fashionResult} />
          <BeautySection category='beauty' large='left' body={beautyResult} />
          <MindSection category='mind' large='right' body={mindResult} />
          <HairSection category='hair' large='left' body={hairResult} />
          <LivingSection category='living' large='right' body={livingResult} />
        </div>
        <TopButton category='' />
      </div>
    </>
  );
};

export default LandingComponent;
