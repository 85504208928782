import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import '../scss/Category.css';
import './component/MenuDrawer.css';
import { useNavigate } from 'react-router-dom';

//main function
export default function TemporaryDrawer({ right, set }: any) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const navigate = useNavigate(); //for navigation to other page
  var currentLocation = window.location.pathname; // check current location of the page

  React.useEffect(() => {
    setState({
      top: false,
      left: false,
      bottom: false,
      right: right,
    });
  }, [right]);
  // Below is the Subdrawer element for desktop
  const HairTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(252, 89, 1, 0.6)',
      color: 'white',
      maxWidth: 220,
      fontSize: '30px',
      border: 'none',
      opacity: 0.6,
    },
  }));

  const BeautyTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(255, 89, 119, 0.9)',
      color: 'white',
      maxWidth: 220,
      fontSize: '30px',
      border: 'none',
      opacity: 0.6,
    },
  }));

  const FashionTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(186, 41, 186, 0.9)',
      color: 'white',
      maxWidth: 220,
      fontSize: '30px',
      border: 'none',
      opacity: 0.6,
    },
  }));

  const MindTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(2, 164, 211, 0.9)',
      color: 'white',
      maxWidth: 220,
      fontSize: '30px',
      border: 'none',
      opacity: 0.6,
    },
  }));

  const LivingTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(158, 198, 0, 0.9)',
      color: 'white',
      maxWidth: 220,
      fontSize: '30px',
      border: 'none',
      opacity: 0.6,
    },
  }));

  //Handle Hair SubDrawer doubleclick
  function handleHairClick(e: any) {
    e.stopPropagation();
    // console.log(e);
    if (e.detail === 1) {
      setOpenHair(true);
      setOpenFashion(false);
      setOpenBeauty(false);
      setOpenLiving(false);
      setOpenMind(false);
    }
    if (openHair && e.detail === 1) {
      navigate('/hair');
      setOpenHair(false);
      setOpenFashion(false);
      setOpenBeauty(false);
      setOpenLiving(false);
      setOpenMind(false);
      window.location.reload();
    }
  }

  //Handle Beauty SubDrawer doubleclick
  function handleBeautyClick(e: any) {
    e.stopPropagation();

    // console.log(e);
    if (e.detail === 1) {
      setOpenBeauty(true);
      setOpenHair(false);
      setOpenFashion(false);
      setOpenLiving(false);
      setOpenMind(false);
    }
    if (openBeauty && e.detail === 1) {
      navigate('/beauty');
      setOpenHair(false);
      setOpenFashion(false);
      setOpenBeauty(false);
      setOpenLiving(false);
      setOpenMind(false);
      window.location.reload();
    }
  }

  //Handle Fashion SubDrawer doubleclick
  function handleFashionClick(e: any) {
    e.stopPropagation();
    // console.log(e);
    if (e.detail === 1) {
      setOpenFashion(true);
      setOpenHair(false);
      setOpenBeauty(false);
      setOpenLiving(false);
      setOpenMind(false);
    }
    if (openFashion && e.detail === 1) {
      navigate('/fashion');
      setOpenHair(false);
      setOpenFashion(false);
      setOpenBeauty(false);
      setOpenLiving(false);
      setOpenMind(false);
      window.location.reload();
    }
  }

  //Handle Mind SubDrawer doubleclick
  function handleMindClick(e: any) {
    e.stopPropagation();
    // console.log(e);
    if (e.detail === 1) {
      setOpenMind(true);
      setOpenHair(false);
      setOpenFashion(false);
      setOpenBeauty(false);
      setOpenLiving(false);
    }
    if (openMind && e.detail === 1) {
      navigate('/mind');
      setOpenHair(false);
      setOpenFashion(false);
      setOpenBeauty(false);
      setOpenLiving(false);
      setOpenMind(false);
      window.location.reload();
    }
  }

  //Handle Living SubDrawer doubleclick
  function handleLivingClick(e: any) {
    e.stopPropagation();
    // console.log(e);
    if (e.detail === 1) {
      setOpenLiving(true);
      setOpenHair(false);
      setOpenFashion(false);
      setOpenBeauty(false);
      setOpenMind(false);
    }
    if (openLiving && e.detail === 1) {
      navigate('/living');
      setOpenHair(false);
      setOpenFashion(false);
      setOpenBeauty(false);
      setOpenLiving(false);
      setOpenMind(false);
      window.location.reload();
    }
  }

  // state of Hair subdrawer, whether it is opened or not
  const [openHair, setOpenHair] = React.useState(false);
  const handleHairTooltipClose = () => {
    setOpenHair(false);
  };
  const handleHairTooltipOpen = () => {
    setOpenHair(true);
  };

  // state of Beauty subdrawer, whether it is opened or not
  const [openBeauty, setOpenBeauty] = React.useState(false);
  const handleBeautyTooltipClose = () => {
    setOpenBeauty(false);
  };
  const handleBeautyTooltipOpen = () => {
    setOpenBeauty(true);
  };

  // state of Fashion subdrawer, whether it is opened or not
  const [openFashion, setOpenFashion] = React.useState(false);
  const handleFashionTooltipClose = () => {
    setOpenFashion(false);
  };
  const handleFashionTooltipOpen = () => {
    setOpenFashion(true);
  };

  // state of Mind subdrawer, whether it is opened or not
  const [openMind, setOpenMind] = React.useState(false);
  const handleMindTooltipClose = () => {
    setOpenMind(false);
  };
  const handleMindTooltipOpen = () => {
    setOpenMind(true);
  };

  // state of Living subdrawer, whether it is opened or not
  const [openLiving, setOpenLiving] = React.useState(false);
  const handleLivingTooltipClose = () => {
    setOpenLiving(false);
  };
  const handleLivingTooltipOpen = () => {
    setOpenLiving(true);
  };

  // For ClickAway function to close all the subdrawer when it is not used
  const handleAllTooltipClose = () => {
    setOpenHair(false);
    setOpenFashion(false);
    setOpenBeauty(false);
    setOpenLiving(false);
    setOpenMind(false);
  };

  React.useEffect(() => {
    setState({
      top: false,
      left: false,
      bottom: false,
      right: right,
    });
  }, [right]);

  // Below is the SubDrawer element for phone
  const PHairTooltip = styled(({ className, ...props }: any) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      onClose={handleHairTooltipClose}
      open={openHair}
      disableHoverListener
      disableTouchListener
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(252, 89, 1, 0.6)',
      color: 'white',
      maxWidth: 220,
      fontSize: '30px',
      border: 'none',
      opacity: 0.6,
    },
  }));

  const PBeautyTooltip = styled(({ className, ...props }: any) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      onClose={handleBeautyTooltipClose}
      open={openBeauty}
      disableHoverListener
      disableTouchListener
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(252, 89, 119, 0.9)',
      color: 'white',
      maxWidth: 220,
      fontSize: '30px',
      border: 'none',
      opacity: 0.6,
    },
  }));

  const PFashionTooltip = styled(({ className, ...props }: any) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      onClose={handleFashionTooltipClose}
      open={openFashion}
      disableHoverListener
      disableTouchListener
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(186, 41, 186, 0.9)',
      color: 'white',
      maxWidth: 220,
      fontSize: '30px',
      border: 'none',
      opacity: 0.6,
    },
  }));

  const PMindTooltip = styled(({ className, ...props }: any) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      onClose={handleMindTooltipClose}
      open={openMind}
      disableHoverListener
      disableTouchListener
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(2, 164, 211, 0.9)',
      color: 'white',
      maxWidth: 220,
      fontSize: '30px',
      border: 'none',
      opacity: 0.6,
    },
  }));

  const PLivingTooltip = styled(({ className, ...props }: any) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      onClose={handleLivingTooltipClose}
      open={openLiving}
      disableHoverListener
      disableTouchListener
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(158, 198, 0, 0.9)',
      color: 'white',
      maxWidth: 220,
      fontSize: '30px',
      border: 'none',
      opacity: 0.6,
    },
  }));

  //MUI toggleDrawer component
  const toggleDrawer = (anchor: string, open: boolean) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    set(open);
    setState({ ...state, [anchor]: open });
  };

  const branchName = process.env.REACT_APP_BRANCH;
  const basename =
    branchName === 'main' ? '' : '/projects/morf/morf-hosting-dev';

  //Mobile verison of SubDrawer
  if (window.screen.width <= 1024) {
    // MUI List component
    const list = (anchor: any) => (
      <Box
        sx={{
          width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 260,
        }}
        role='presentation'
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <ClickAwayListener onClickAway={handleAllTooltipClose}>
          <table id='mobileDrawerTable'>
            <tr>
              <td>
                <a href={basename + '/'}>
                  <button className='btn1'>
                    <span
                      style={{
                        opacity: currentLocation === '' ? '1' : '0.4',
                        fontWeight: currentLocation === '' ? 'bold' : '100',
                      }}
                    >
                      {' '}
                      Home
                    </span>
                  </button>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href={basename + '/about'}>
                  <button className='btn2'>
                    <span
                      style={{
                        opacity: currentLocation === '/about' ? '1' : '0.4',
                        fontWeight:
                          currentLocation === '/about' ? 'bold' : '100',
                      }}
                    >
                      About
                    </span>
                  </button>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href={basename + '/people'}>
                  <button className='btn3'>
                    <span
                      style={{
                        opacity: currentLocation === '/people' ? '1' : '0.4',
                        fontWeight:
                          currentLocation === '/people' ? 'bold' : '100',
                      }}
                    >
                      People
                    </span>
                  </button>
                </a>
              </td>
            </tr>

            <PFashionTooltip
              title={
                <React.Fragment>
                  <table id='fashionSubdrawerTable'>
                    <tr>
                      <td>
                        <a href='/brand-story'>
                          <button className='fashion1'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/brand-story'
                                    ? '1'
                                    : '0.4',
                                fontWeight:
                                  currentLocation === '/brand-story'
                                    ? 'bold'
                                    : '100',
                              }}
                            >
                              Brand Story
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href='/styling'>
                          <button className='fashion2'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/styling' ? '1' : '0.4',
                                fontWeight:
                                  currentLocation === '/styling'
                                    ? 'bold'
                                    : '100',
                              }}
                            >
                              Styling
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href='/news'>
                          <button className='fashion3'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/news' ? '1' : '0.4',
                                fontWeight:
                                  currentLocation === '/news' ? 'bold' : '100',
                              }}
                            >
                              News
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                  </table>
                </React.Fragment>
              }
              placement='left'
            >
              <tr>
                <td>
                  <button
                    className='btn4'
                    id='inner2'
                    onClick={handleFashionClick}
                  >
                    <span
                      style={{
                        opacity: currentLocation === '/fashion' ? '1' : '0.4',
                        fontWeight:
                          currentLocation === '/fashion' ? 'bold' : '100',
                      }}
                    >
                      Fashion
                    </span>
                  </button>
                </td>
              </tr>
            </PFashionTooltip>

            <PBeautyTooltip
              title={
                <React.Fragment>
                  <table id='beautySubdrawerTable'>
                    <tr>
                      <td>
                        <a href='/skin-care'>
                          <button className='beauty1'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/skin-care'
                                    ? '1'
                                    : '0.4',
                                fontWeight:
                                  currentLocation === '/skin-care'
                                    ? 'bold'
                                    : '100',
                              }}
                            >
                              Skin-care
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href='/make-up'>
                          <button className='beauty2'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/make-up' ? '1' : '0.4',
                                fontWeight:
                                  currentLocation === '/make-up'
                                    ? 'bold'
                                    : '100',
                              }}
                            >
                              Make-up
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                  </table>
                </React.Fragment>
              }
              placement='left'
            >
              <tr>
                <td>
                  <button
                    className='btn5'
                    id='inner1'
                    onClick={handleBeautyClick}
                  >
                    <span
                      style={{
                        opacity: currentLocation === '/beauty' ? '1' : '0.4',
                        fontWeight:
                          currentLocation === '/beauty' ? 'bold' : '100',
                      }}
                    >
                      Beauty
                    </span>
                  </button>
                </td>
              </tr>
            </PBeautyTooltip>

            <PMindTooltip
              title={
                <React.Fragment>
                  <table id='mindSubdrawerTable'>
                    <tr>
                      <td>
                        <a href='/movie'>
                          <button className='mind1'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/movie' ? '1' : '0.4',
                                fontWeight:
                                  currentLocation === '/movie' ? 'bold' : '100',
                              }}
                            >
                              Movie
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href='/book'>
                          <button className='mind2'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/book' ? '1' : '0.4',
                                fontWeight:
                                  currentLocation === '/book' ? 'bold' : '100',
                              }}
                            >
                              Book
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href='/design'>
                          <button className='mind3'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/design' ? '1' : '0.4',
                                fontWeight:
                                  currentLocation === '/design'
                                    ? 'bold'
                                    : '100',
                              }}
                            >
                              Design
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href='/art'>
                          <button className='mind4'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/art' ? '1' : '0.4',
                                fontWeight:
                                  currentLocation === '/art' ? 'bold' : '100',
                              }}
                            >
                              Art
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href='/culture'>
                          <button className='mind5'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/culture' ? '1' : '0.4',
                                fontWeight:
                                  currentLocation === '/culture'
                                    ? 'bold'
                                    : '100',
                              }}
                            >
                              Culture
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                  </table>
                </React.Fragment>
              }
              placement='left'
            >
              <tr>
                <td>
                  <button
                    className='btn6'
                    id='inner3'
                    onClick={handleMindClick}
                  >
                    <span
                      style={{
                        opacity: currentLocation === '/mind' ? '1' : '0.4',
                        fontWeight:
                          currentLocation === '/mind' ? 'bold' : '100',
                      }}
                    >
                      Mind
                    </span>
                  </button>
                </td>
              </tr>
            </PMindTooltip>

            <PHairTooltip
              title={
                <React.Fragment>
                  <table id='hairSubdrawerTable'>
                    <tr>
                      <td>
                        <a href='/product'>
                          <button className='hair1'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/product' ? '1' : '0.4',
                                fontWeight:
                                  currentLocation === '/product'
                                    ? 'bold'
                                    : '100',
                              }}
                            >
                              Product
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href='/hairstyle'>
                          <button className='hair2'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/hairstyle'
                                    ? '1'
                                    : '0.4',
                                fontWeight:
                                  currentLocation === '/hairstyle'
                                    ? 'bold'
                                    : '100',
                              }}
                            >
                              Hairstyle
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                  </table>
                </React.Fragment>
              }
              placement='left'
            >
              <tr>
                <td>
                  <button className='btn7' id='inner' onClick={handleHairClick}>
                    <span
                      style={{
                        opacity: currentLocation === '/hair' ? '1' : '0.4',
                        fontWeight:
                          currentLocation === '/hair' ? 'bold' : '100',
                      }}
                    >
                      Hair
                    </span>
                  </button>
                </td>
              </tr>
            </PHairTooltip>

            <PLivingTooltip
              title={
                <React.Fragment>
                  <table id='livingSubdrawerTable'>
                    <tr>
                      <td>
                        <a href='/food'>
                          <button className='living1'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/food' ? '1' : '0.4',
                                fontWeight:
                                  currentLocation === '/food' ? 'bold' : '100',
                              }}
                            >
                              Food
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href='/sport'>
                          <button className='living2'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/sport' ? '1' : '0.4',
                                fontWeight:
                                  currentLocation === '/sport' ? 'bold' : '100',
                              }}
                            >
                              Sport
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href='/game'>
                          <button className='living3'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/game' ? '1' : '0.4',
                                fontWeight:
                                  currentLocation === '/game' ? 'bold' : '100',
                              }}
                            >
                              Game
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href='/travel'>
                          <button className='living4'>
                            <span
                              style={{
                                opacity:
                                  currentLocation === '/travel' ? '1' : '0.4',
                                fontWeight:
                                  currentLocation === '/travel'
                                    ? 'bold'
                                    : '100',
                              }}
                            >
                              Travel
                            </span>
                          </button>
                        </a>
                      </td>
                    </tr>
                  </table>
                </React.Fragment>
              }
              placement='left'
            >
              <tr>
                <td>
                  <button
                    className='btn8'
                    id='inner4'
                    onClick={handleLivingClick}
                  >
                    <span
                      style={{
                        opacity: currentLocation === '/living' ? '1' : '0.4',
                        fontWeight:
                          currentLocation === '/living' ? 'bold' : '100',
                      }}
                    >
                      Living
                    </span>
                  </button>
                </td>
              </tr>
            </PLivingTooltip>
            <tr>
              <td>
                <a href={basename + '/subscription'}>
                  <button className='btn9'>
                    <span
                      style={{
                        opacity:
                          currentLocation === '/subscription' ? '1' : '0.4',
                        fontWeight:
                          currentLocation === '/subscription' ? 'bold' : '100',
                      }}
                    >
                      Subscription
                    </span>
                  </button>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href={basename + '/contact'}>
                  <button className='btn10'>
                    <span
                      style={{
                        opacity: currentLocation === '/contact' ? '1' : '0.4',
                        fontWeight:
                          currentLocation === '/contact' ? 'bold' : '100',
                      }}
                    >
                      Contact
                    </span>
                  </button>
                </a>
              </td>
            </tr>
          </table>
        </ClickAwayListener>
      </Box>
    );

    return (
      <div>
        {['right'].map((anchor) => (
          <React.Fragment key={anchor}>
            <Drawer
              BackdropProps={{ invisible: true }}
              transitionDuration={{ enter: 1000, exit: 1500 }}
              PaperProps={{
                sx: {
                  backgroundColor: '#1A1A1A',
                  opacity: '0.75',
                  color: 'white',
                  overflow: 'hidden',
                  width: {
                    xs: 180,
                    sm: 380,
                    md: 400,
                    lg: 400,
                    xl: 400,
                  },
                },
              }}
              // type assertion is needed for 'anchor'
              anchor={anchor as 'bottom' | 'left' | 'right' | 'top' | undefined}
              open={state[anchor as keyof typeof state]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    );
  }

  // Desktop Function MenuDrawer
  else {
    const list = (anchor: any) => (
      <Box
        sx={{
          width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 260,
        }}
        role='presentation'
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <table id='mobileDrawerTable'>
          <tr>
            <td>
              <a href={basename + '/'}>
                <button className='btn1'>
                  <span
                    style={{
                      opacity: currentLocation === '' ? '1' : '0.4',
                      fontWeight: currentLocation === '' ? 'bold' : '100',
                    }}
                  >
                    {' '}
                    Home
                  </span>
                </button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href={basename + '/about'}>
                <button className='btn2'>
                  <span
                    style={{
                      opacity: currentLocation === '/about' ? '1' : '0.4',
                      fontWeight: currentLocation === '/about' ? 'bold' : '100',
                    }}
                  >
                    About
                  </span>
                </button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href={basename + '/people'}>
                <button className='btn3'>
                  <span
                    style={{
                      opacity: currentLocation === '/people' ? '1' : '0.4',
                      fontWeight:
                        currentLocation === '/people' ? 'bold' : '100',
                    }}
                  >
                    People
                  </span>
                </button>
              </a>
            </td>
          </tr>
          <FashionTooltip
            title={
              <React.Fragment>
                <table id='fashionSubdrawerTable'>
                  <tr>
                    <td>
                      <a href='/brand-story'>
                        <button className='fashion1'>
                          <span
                            style={{
                              opacity:
                                currentLocation === '/brand-story'
                                  ? '1'
                                  : '0.4',
                              fontWeight:
                                currentLocation === '/brand-story'
                                  ? 'bold'
                                  : '100',
                            }}
                          >
                            Brand Story
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='/styling'>
                        <button className='fashion2'>
                          <span
                            style={{
                              opacity:
                                currentLocation === '/styling' ? '1' : '0.4',
                              fontWeight:
                                currentLocation === '/styling' ? 'bold' : '100',
                            }}
                          >
                            Styling
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='/news'>
                        <button className='fashion3'>
                          <span
                            style={{
                              opacity:
                                currentLocation === '/news' ? '1' : '0.4',
                              fontWeight:
                                currentLocation === '/news' ? 'bold' : '100',
                            }}
                          >
                            News
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                </table>
              </React.Fragment>
            }
            placement='left'
          >
            <tr>
              <td>
                <a href={basename + '/fashion'}>
                  <button className='btn4'>
                    <span
                      style={{
                        opacity: currentLocation === '/fashion' ? '1' : '0.4',
                        fontWeight:
                          currentLocation === '/fashion' ? 'bold' : '100',
                      }}
                    >
                      Fashion
                    </span>
                  </button>
                </a>
              </td>
            </tr>
          </FashionTooltip>

          <BeautyTooltip
            title={
              <React.Fragment>
                <table id='beautySubdrawerTable'>
                  <tr>
                    <td>
                      <a href='/skin-care'>
                        <button className='beauty1'>
                          <span
                            style={{
                              opacity:
                                currentLocation === '/skin-care' ? '1' : '0.4',
                              fontWeight:
                                currentLocation === '/skin-care'
                                  ? 'bold'
                                  : '100',
                            }}
                          >
                            Skin-care
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='/make-up'>
                        <button className='beauty2'>
                          <span
                            style={{
                              opacity:
                                currentLocation === '/make-up' ? '1' : '0.4',
                              fontWeight:
                                currentLocation === '/make-up' ? 'bold' : '100',
                            }}
                          >
                            Make-up
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                </table>
              </React.Fragment>
            }
            placement='left'
          >
            <tr>
              <td>
                <a href={basename + '/beauty'}>
                  <button className='btn5'>
                    <span
                      style={{
                        opacity: currentLocation === '/beauty' ? '1' : '0.4',
                        fontWeight:
                          currentLocation === '/beauty' ? 'bold' : '100',
                      }}
                    >
                      Beauty
                    </span>
                  </button>
                </a>
              </td>
            </tr>
          </BeautyTooltip>
          <MindTooltip
            title={
              <React.Fragment>
                <table id='mindSubdrawerTable'>
                  <tr>
                    <td>
                      <a href='/movie'>
                        <button className='mind1'>
                          <span
                            style={{
                              opacity:
                                currentLocation === '/movie' ? '1' : '0.4',
                              fontWeight:
                                currentLocation === '/movie' ? 'bold' : '100',
                            }}
                          >
                            Movie
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='/book'>
                        <button className='mind2'>
                          <span
                            style={{
                              opacity:
                                currentLocation === '/book' ? '1' : '0.4',
                              fontWeight:
                                currentLocation === '/book' ? 'bold' : '100',
                            }}
                          >
                            Book
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='/design'>
                        <button className='mind3'>
                          <span
                            style={{
                              opacity:
                                currentLocation === '/design' ? '1' : '0.4',
                              fontWeight:
                                currentLocation === '/design' ? 'bold' : '100',
                            }}
                          >
                            Design
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='/art'>
                        <button className='mind4'>
                          <span
                            style={{
                              opacity: currentLocation === '/art' ? '1' : '0.4',
                              fontWeight:
                                currentLocation === '/art' ? 'bold' : '100',
                            }}
                          >
                            Art
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='/culture'>
                        <button className='mind5'>
                          <span
                            style={{
                              opacity:
                                currentLocation === '/culture' ? '1' : '0.4',
                              fontWeight:
                                currentLocation === '/culture' ? 'bold' : '100',
                            }}
                          >
                            Culture
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                </table>
              </React.Fragment>
            }
            placement='left'
          >
            <tr>
              <td>
                <a href={basename + '/mind'}>
                  <button className='btn6'>
                    <span
                      style={{
                        opacity: currentLocation === '/mind' ? '1' : '0.4',
                        fontWeight:
                          currentLocation === '/mind' ? 'bold' : '100',
                      }}
                    >
                      Mind
                    </span>
                  </button>
                </a>
              </td>
            </tr>
          </MindTooltip>

          <HairTooltip
            title={
              <React.Fragment>
                <table id='hairSubdrawerTable'>
                  <tr>
                    <td>
                      <a href='/product'>
                        <button className='hair1'>
                          <span
                            style={{
                              opacity:
                                currentLocation === '/product' ? '1' : '0.4',
                              fontWeight:
                                currentLocation === '/product' ? 'bold' : '100',
                            }}
                          >
                            Product
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='/hairstyle'>
                        <button className='hair2'>
                          <span
                            style={{
                              opacity:
                                currentLocation === '/hairstyle' ? '1' : '0.4',
                              fontWeight:
                                currentLocation === '/hairstyle'
                                  ? 'bold'
                                  : '100',
                            }}
                          >
                            Hairstyle
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                </table>
              </React.Fragment>
            }
            placement='left'
          >
            <tr>
              <td>
                <a href={basename + '/hair'}>
                  <button className='btn7'>
                    <span
                      style={{
                        opacity: currentLocation === '/hair' ? '1' : '0.4',
                        fontWeight:
                          currentLocation === '/hair' ? 'bold' : '100',
                      }}
                    >
                      Hair
                    </span>
                  </button>
                </a>
              </td>
            </tr>
          </HairTooltip>

          <LivingTooltip
            title={
              <React.Fragment>
                <table id='livingSubdrawerTable'>
                  <tr>
                    <td>
                      <a href='/food'>
                        <button className='living1'>
                          <span
                            style={{
                              opacity:
                                currentLocation === '/food' ? '1' : '0.4',
                              fontWeight:
                                currentLocation === '/food' ? 'bold' : '100',
                            }}
                          >
                            Food
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='/sport'>
                        <button className='living2'>
                          <span
                            style={{
                              opacity:
                                currentLocation === '/sport' ? '1' : '0.4',
                              fontWeight:
                                currentLocation === '/sport' ? 'bold' : '100',
                            }}
                          >
                            Sport
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='/game'>
                        <button className='living3'>
                          <span
                            style={{
                              opacity:
                                currentLocation === '/game' ? '1' : '0.4',
                              fontWeight:
                                currentLocation === '/game' ? 'bold' : '100',
                            }}
                          >
                            Game
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='/travel'>
                        <button className='living4'>
                          <span
                            style={{
                              opacity:
                                currentLocation === '/travel' ? '1' : '0.4',
                              fontWeight:
                                currentLocation === '/travel' ? 'bold' : '100',
                            }}
                          >
                            Travel
                          </span>
                        </button>
                      </a>
                    </td>
                  </tr>
                </table>
              </React.Fragment>
            }
            placement='left'
          >
            <tr>
              <td>
                <a href={basename + '/living'}>
                  <button className='btn8'>
                    <span
                      style={{
                        opacity: currentLocation === '/living' ? '1' : '0.4',
                        fontWeight:
                          currentLocation === '/living' ? 'bold' : '100',
                      }}
                    >
                      Living
                    </span>
                  </button>
                </a>
              </td>
            </tr>
          </LivingTooltip>
          <tr>
            <td>
              <a href={basename + '/subscription'}>
                <button className='btn9'>
                  <span
                    style={{
                      opacity:
                        currentLocation === '/subscription' ? '1' : '0.4',
                      fontWeight:
                        currentLocation === '/subscription' ? 'bold' : '100',
                    }}
                  >
                    Subscription
                  </span>
                </button>
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href={basename + '/contact'}>
                <button className='btn10'>
                  <span
                    style={{
                      opacity: currentLocation === '/contact' ? '1' : '0.4',
                      fontWeight:
                        currentLocation === '/contact' ? 'bold' : '100',
                    }}
                  >
                    Contact
                  </span>
                </button>
              </a>
            </td>
          </tr>
        </table>
      </Box>
    );

    return (
      <div>
        {['right'].map((anchor) => (
          <React.Fragment key={anchor}>
            <Drawer
              transitionDuration={{ enter: 1000, exit: 1500 }}
              BackdropProps={{ invisible: true }}
              PaperProps={{
                sx: {
                  backgroundColor: '#1A1A1A',
                  opacity: '0.75',
                  color: 'white',
                  overflow: 'hidden',
                  width: {
                    xs: 180,
                    sm: 380,
                    md: 400,
                    lg: 400,
                    xl: 400,
                  },
                },
              }}
              // type assertion is needed for 'anchor'
              anchor={anchor as 'bottom' | 'left' | 'right' | 'top' | undefined}
              open={state[anchor as keyof typeof state]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    );
  }
}
