import { useEffect, useState, useContext } from 'react';
import axios, { AxiosResponse } from 'axios';
import { LoadingContext } from '../../../Loading/component/Context';
// type and init
import { PostBody, initPostBody } from '../../../type/type';
import { generatePostBody } from '../../Strapi'

export default function Article(postId: string): PostBody {
  const { loadingFinish } = useContext(LoadingContext);
  const [error, setError] = useState('');
  const url =
    'https://morf-prod.agileexlab.net/api/posts/' +
    postId +
    '?populate=%2A&pagination[limit]=-1';
  const authentication = {
    headers: {
      Authorization:
        'Bearer ' +
        '436b7d7cd0b85ff27e2dde37c7255b0dd7c1791ed102543b6e2dacb57ec69eec708496ee2fd051a81b58a86dd1b44081e64e5cc3461b99740a3ce9ed2aa4bfbbb9f96b4db2197df862fc26cb8332693ea515179a287fd6f94f97628574af396ba4040b52e08d15a6adb4e401858c650d7ed508975f978b11cb210a76053554fe',
    },
  };
  const [fetchedData, setFetchedData] = useState<AxiosResponse | null>(null);
  useEffect(() => {
    const getData = async () => {
      const data = await axios
        .get(url, authentication)
        .then((res) => {
          setFetchedData(res);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          loadingFinish();
        });
    };
    getData();
  }, []);

  const postList = fetchedData
    ? generatePostBody(fetchedData?.data.data)
    : initPostBody();
  return postList;
}
