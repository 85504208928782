// Helper functions on filtering postBody by category
import { PostBody } from "../../../type/type";

export default function Latest(postBody: PostBody[], quantity: number): PostBody[] {
  if (postBody.length <= 0) {
    return [];
  }
  var reversedList : PostBody[] = [];
  for (var i = postBody.length - 1; i >= postBody.length - quantity; i--) {
    reversedList.push(postBody[i]);
  }
  return reversedList;
}