import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Swiper as SwiperRef } from 'swiper';
import './component/ImageSwiper.css';

import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import '../scss/Category.css';
import { ImageHandler } from '../ImageHandler';
import { FallBackImage } from '../FallBackImage';

// image swiper on article page (mobile)
function ImageSwiper(props: any) {
  const swiperRef = useRef<SwiperRef>();

  const handleSlide = (index: any) => {
    swiperRef.current?.slideTo(index + 1, 300, true);
  };

  const initBackground = (swiper: any) => {
    swiper.slideTo(0);
  };

  useEffect(() => {
    handleSlide(props.imgIndex);
  });

  return (
    <div className='Image-Swiper-Section-Mobile' style={{ minHeight: '330px' }}>
      <Swiper
        loop={true}
        navigation={true}
        id={'Fashion-Image-Swiper-Mobile'}
        className='My-Swiper'
        modules={[Navigation, Pagination]}
        observer={true}
        uniqueNavElements={false}
        onAfterInit={(swiper) => initBackground(swiper)}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {props.media
          .slice(0, props.headingCount)
          .map((item: any, index: number) => {
            return (
              <SwiperSlide key={`image-swiper-img-slide-${index}`}>
                <img
                  className='Article-Image'
                  src={ImageHandler('https://morf-prod.agileexlab.net', item, 2)}
                  onError={({ currentTarget }) => { FallBackImage(currentTarget) }}
                  alt=''
                />
              </SwiperSlide>
            );
          })}
      </Swiper >
    </div >
  );
}

export default ImageSwiper;
