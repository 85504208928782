import React from 'react';
import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import '../scss/Category.css';

interface Props {
  category: string;
}

// a category icon within a colored background, used in landing page category sections (mobile)
const CategoryBar: React.FC<Props> = (props: Props) => {
  const CategoryBarPath = require(`../../assets/img/${props.category}-icon.png`)
  return (
    <div className={`Category-Bar ${props.category}-bgcolor`}>
      <div className='Category-Icon-Mobile' style={{ backgroundImage: `url(${CategoryBarPath})` }} />
    </div>
  )
}

export default CategoryBar;
