import { Card } from '../../../../common/Card';
import { CardGroup } from '../../../../common/CardGroup';
import { CategorySwiper } from '../../../../common/CategorySwiper';
import { CategoryExcerptReadmore } from '../../../../common/CategoryExcerptReadmore';
import { CategoryHeader } from '../../../../common/CategoryHeader';
import React from 'react';
import '../../../../common/scss/Common.css';
import '../../../../common/scss/Category.css';
import '../../../../common/scss/LatestSwiper.css';
import { PostBody } from '../../../../common/type/type';
import { ImageHandler } from '../../../../common/ImageHandler';

interface Props {
  category: string
  large: string
  body: PostBody[]
}

const PeopleSection: React.FC<Props> = (props) => {
  const latestPosts: PostBody[] = props.body;
  let first = latestPosts && latestPosts.slice(0, 1)[0];
  return (
    <>
      {/* Category Swiper (mobile) */}
      <CategorySwiper category={props.category} large={props.large} latestPosts={latestPosts} />

      {/* Category Section (desktop) => structure should be different across different categories */}
      <div className='Category-Sections Content-Container'>
        <div className={`Category-Section ${props.category}`}>
          <div className='Category-Section-Content'>
            {/* Icon and divider bars nearby */}
            <CategoryHeader category={props.category} />
            {/* Featured posts layout and colored scroll */}
            <div className='Category-Section-Imgs-Layout Category-Section-Imgs-Layout-Large Category-Section-Two-Col-Layout'>
              {latestPosts.length > 0 ? (
                <div className='First-Item-Div'>
                  < Card
                    use={'landing'}
                    first={false}
                    category={`${props.category}`}
                    // first image of the post
                    imgSrc={ImageHandler('https://morf-prod.agileexlab.net', first.attributes.desktop_169[0], 2)}
                    title={first.attributes.title}
                    link={'/article'}
                    id={first.id}
                  />
                  <CategoryExcerptReadmore category={props.category} postTitle={first.attributes?.title} postDesc={first.attributes?.description} id={first.id} />
                </div>
              )
                : <></>}
              <div className={`Other-Item-Div Right-Scroll Colored-Scroll ${props.category}-scroll`}>
                <CardGroup category={props.category} latestPosts={latestPosts} />
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </>

  );
}

export default PeopleSection;
