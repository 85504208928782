import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ScrollToTop } from './hook/scrollToTop';
import { store } from './common/Reducers/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const branchName = process.env.REACT_APP_BRANCH;
const basename =
  branchName === 'main' ? '/' : '/projects/morf/morf-hosting-dev/';
//changed basename

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={basename}>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
