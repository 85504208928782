import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../../../../common/scss/Common.css';
import '../../ArticlePageLayout.css';

const ArticleSkeletonMobile = (props: any) => {
  const CategoryBgPath = props?.content?.attributes?.categories[0].name
    ? require(`../../../../../assets/img/${props.content.attributes.categories[0].name}-article-bg.png`)
    : '';
  return (
    <div
      className='Article-Content-Container-mobile'
      style={{ backgroundImage: `url(${CategoryBgPath})` }}
    >
      <div className='Article-Content'>
        <div className='Article-Content-Col'>
          <div className='Image-Column'>
            <div className='Image-Swiper'>
              <Skeleton style={{ paddingTop: '125%' }} />
            </div>
          </div>
          <div className={'Text-Column'}>
            <p className='Article-Text-Title'>
              <Skeleton />
            </p>
            <p className='Article-Text-Content'>
              <Skeleton count={10} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleSkeletonMobile;
