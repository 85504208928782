import React, { useEffect, useRef } from "react";
import { Header } from '../../common/Header';
import { Footer } from '../../common/Footer';

import '../../common/scss/Common.css';
import '../../common/scss/LatestSwiper.css';
import '../../common/scss/Category.css';
import './component/Contact.css';
import { HashBg } from '../../common/HashBg';

import { ContactInfo } from "./component/ContactInfo";
import { ContactMap } from "./component/ContactMap";

import { gsap } from "gsap";
import { ContactPageTitle } from "./component/ContactPageTitle";
import { TopButton } from "../../common/TopButton";

function ContactPage() {
  const titleRef = useRef(null);
  const mapRef = useRef(null);
  const infoRef = useRef(null);

  useEffect(() => {
    document.title = `Contact – MORF, FROM now on`;

    // orange hash bg
    const hairHash = document.getElementsByClassName('Hair-Hash')[0] as HTMLElement;
    hairHash.style.opacity = '100%';

    // fade in animation
    gsap.fromTo(titleRef.current, { autoAlpha: 0, y: 50 }, { duration: 1.5, autoAlpha: 1.5, y: 0 })
    gsap.fromTo(mapRef.current, { autoAlpha: 0, y: 50 }, { delay: 1, duration: 1.5, autoAlpha: 1, y: 0 })
    gsap.fromTo(infoRef.current, { autoAlpha: 0, y: 50 }, { delay: 1.5, duration: 1.5, autoAlpha: 1, y: 0 })
  }, []);

  return (
    <div className='Page-Container'>
      <Header />
      <HashBg category={'hair'} />
      <div className='Page-Content'>
        <div className='Content-Container'>
          <div className='Contact-Page-Content' >
            <ContactPageTitle ref={titleRef} />
            <ContactMap ref={mapRef} />
            <ContactInfo ref={infoRef} />
            <img className='Contact-Page-Morf-Slogan' src={require('../../assets/img/morf-slogan-black.png')} alt='' />
          </div>
        </div>
        <TopButton category='' />
      </div>
      <Footer />
    </div>
  );
}

export default ContactPage;
