import React from 'react';
import '../subscription.css'


const Instagram = React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
  <div className='Instagram-Div' ref={ref}>
  
    <a href='https://www.instagram.com/morf.today/'><img className='Instagram-Image'
          src={require(`../../../../assets/img/subscription-03.png`)} alt='' />
  </a>
  </div>
))

export default Instagram;
