import React from 'react';
import '../Contact.css';

const ContactMap = React.forwardRef<HTMLDivElement, unknown>((props, ref) => (
  <div className='Contact-Map-Div' ref={ref}>
    <iframe
      className='Contact-Map'
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1098.6798858026311!2d114.19729283411914!3d22.33570541210897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340407fb4bb3079f%3A0x6099e9d1774b529e!2z5p2x5YKyIEhvcml6b24gRWFzdA!5e0!3m2!1szh-TW!2shk!4v1708420890309!5m2!1szh-TW!2shk"
      style={{ border: 0 }}
      // allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title='morf office map' />
  </div >
))

export default ContactMap;
