import { Card } from '../Card';
import React from 'react';
import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import '../scss/Category.css';

import { PostBody, initPostBody } from '../type/type';
import { ImageHandler } from '../ImageHandler';

interface Props {
  category: string;
  latestPosts?: PostBody[];
}

// a vertical group of 6 images, used in landing page people, hair, beauty, fashion and mind section (desktop)
const CardGroup: React.FC<Props> = (props: Props) => {
  // 6 posts from the latest post
  const latestPostsFromSecond = props.latestPosts
    ? props.latestPosts.slice(1, 7)
    : [initPostBody()];

  return (
    <>
      {latestPostsFromSecond.map((post: PostBody) => (
        <Card
          key={`card-key-${post.id}`}
          use={'landing'}
          first={false}
          category={`${props.category}`}
          imgSrc={ImageHandler('https://morf-prod.agileexlab.net', post.attributes?.desktop_169[0], 1)}
          title={post.attributes?.title ? post.attributes.title : 'Null'}
          link={'/article'}
          id={post.id ? post.id : 6788}
        />
      ))}
    </>
  );
};

export default CardGroup;
