import { useComponentId } from '../../hook/useComponentId';
import './component/Card.css';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import '../scss/Category.css';
import { FallBackImage } from '../FallBackImage';

interface Props {
  category: string;
  title: string;
  imgSrc: string;
  link: string;
  use: string;
  first: boolean;
  id: number;
}

// a card component that contains an image and its title
const Card: React.FC<Props> = ({
  category,
  title,
  imgSrc,
  link,
  use,
  first,
  id,
}) => {
  let cardCount = useComponentId();

  const colorList = [
    { categoryFilter: 'people', color: 'var(--secondary-yellow)' },
    { categoryFilter: 'hair', color: 'var(--primary-orange)' },
    { categoryFilter: 'beauty', color: 'var(--secondary-pink)' },
    { categoryFilter: 'fashion', color: 'var(--secondary-purple)' },
    { categoryFilter: 'mind', color: 'var(--secondary-blue)' },
    { categoryFilter: 'living', color: 'var(--secondary-green)' },
  ];

  const filteredColor = colorList.filter((data) => {
    return data.categoryFilter === category;
  });
  const navigate = useNavigate();

  return (
    <div className={`${use}-card-wrapper`}>
      <span
        className={`${use}-card-link`}
        onClick={() => navigate(link + `/${id}`)}
      >
        <div className='sixteen-nine-img'>
          <img className={`${use}-card-image`} src={imgSrc} alt=''
            onError={({ currentTarget }) => FallBackImage(currentTarget)}
          />
        </div>
        {filteredColor.map((filtered) => {
          return (
            <div
              className={`Title-Font ${use}-card-title  ${first && 'First-Item'
                }`}
              key={`card-key-${cardCount}`}
              style={{ color: filtered.color }}
            >
              {title}
            </div>
          );
        })}
      </span>
    </div>
  );
};

export default Card;
