import React, { useEffect, useRef } from "react";
import '../../common/scss/Common.css';
import '../../common/scss/LatestSwiper.css';
import '../../common/scss/Category.css';
import { Instagram } from "./component/Instagram";
import { Newsletter } from "./component/Newsletter";
import { Header } from '../../common/Header';
import { Footer } from '../../common/Footer';
import { HashBg } from '../../common/HashBg';

import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger'
import { TopButton } from "../../common/TopButton";
gsap.registerPlugin(ScrollTrigger)


function SubscriptionPage() {
  const NewsletterRef = useRef(null);
  const InstagramRef = useRef(null);

  useEffect(() => {
    document.title = `Subscription – MORF, FROM now on`;

    // orange hash bg
    const hairHash = document.getElementsByClassName('Hair-Hash')[0] as HTMLElement;
    hairHash.style.opacity = '100%';

    // fade in animation
    gsap.fromTo(NewsletterRef.current, { autoAlpha: 0, y: 200 }, { duration: 1.5, autoAlpha: 1.5, y: 0 })
    gsap.fromTo(InstagramRef.current, { autoAlpha: 0, y: 200 }, { delay: 0.3, duration: 1.5, autoAlpha: 1, y: 0 })
  }, []);

  return (
    <div className="Page-Container">
      <Header />
      <HashBg category={'hair'} />
      <div className="Page-Content">
        <div className='Content-Container'>
          <Newsletter ref={NewsletterRef} />
          <Instagram ref={InstagramRef} />
        </div>
        <TopButton category='' />
      </div>
      <Footer />
    </div>
  );
}

export default SubscriptionPage;
