import { Header } from '../../common/Header';
import { Footer } from '../../common/Footer';
import React from 'react'
import '../../common/scss/Common.css';
import '../../common/scss/LatestSwiper.css';
import '../../common/scss/Category.css';
import { LoadingProvider } from '../../common/Loading/component/Provider'
import "swiper/css";
import "swiper/css/navigation";
import { LandingComponent } from './component/landing';

function LandingPage() {
  return (
    <div className='Page-Container'>
      <Header />
      <LoadingProvider>
        <LandingComponent /> 
      </LoadingProvider>
      <Footer />
    </div>
  );
}

export default LandingPage;
