// import './component/PrivacyPolicyPage.css';
import { Header } from '../../common/Header';
import { Footer } from '../../common/Footer';
// import { Strapi } from '../../common/Strapi';
import React from 'react';
import '../../common/scss/Common.css';
import '../../common/scss/LatestSwiper.css';
import '../../common/scss/Category.css';


function TestPage() {
  return (
    <div>
      <Header />
      {/* <Strapi/> */}
      <Footer />
    </div>
  )
}

export default TestPage;