import React, { useContext } from 'react';
import { LoadingContext } from '../Loading/component/Context';
import './component/SpinnerStyle/SpinnerStyle.css';

const Spinner = () => {
  const { loadingBool } = useContext(LoadingContext);
  return (
    <>
      {loadingBool && (
        <div className='spinner-overlay'>
          <div className='spinner' />
        </div>
      )}
    </>
  );
};

export default Spinner;
