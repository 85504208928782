
import './component/ReadMore.css'
import { useNavigate } from 'react-router-dom';
import React from 'react';

import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import '../scss/Category.css';
interface Props {
  id: number;
  category: string;
  use: 'result' | 'landing';
}

// 'Read more' button
const ReadMore: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  return (
    <div>
      <span className={`Read-More ${props.category}-color`}
        onClick={() => navigate(`/article/${props.id}`)} style={{ cursor: 'pointer' }}>
        {props.use === 'result' ? 'Read More »' : 'Read More...'}
      </span>
    </div>
  );
}

export default ReadMore;
