import React, { useEffect, useRef } from "react";
import { Header } from '../../common/Header';
import { Footer } from '../../common/Footer';

import '../../common/scss/Common.css';
import '../../common/scss/LatestSwiper.css';
import '../../common/scss/Category.css';
import './component/About.css';
import { HashBg } from '../../common/HashBg';

import { AboutEng } from "./component/AboutEng";

import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger'
import { AboutPageTitle } from "./component/AboutPageTitle";
import { AboutChi } from "./component/AboutChi";
import { TopButton } from "../../common/TopButton";
gsap.registerPlugin(ScrollTrigger)

function AboutPage() {
  const titleRef = useRef(null);
  const imgRef = useRef(null);
  const engAboutRef = useRef(null);
  const chiAboutRef = useRef(null);

  useEffect(() => {
    document.title = `About – MORF, FROM now on`;

    // orange hash bg
    const hairHash = document.getElementsByClassName('Hair-Hash')[0] as HTMLElement;
    hairHash.style.opacity = '100%';

    gsap.fromTo(titleRef.current, { autoAlpha: 0, y: 200 }, { duration: 1.5, autoAlpha: 1.5, y: 0 })
    gsap.fromTo(imgRef.current, { autoAlpha: 0, y: 200 }, { duration: 1.5, autoAlpha: 1, y: 0 })
    gsap.fromTo(engAboutRef.current, { autoAlpha: 0, y: 200 }, { delay: 0.3, duration: 1.5, autoAlpha: 1, y: 0 })
    gsap.fromTo(chiAboutRef.current, { autoAlpha: 0, y: 200 }, { scrollTrigger: { trigger: chiAboutRef.current }, delay: 0.5, duration: 1.5, autoAlpha: 1, y: 0 });
  }, []);

  return (
    <div className='Page-Container'>
      <Header />
      <HashBg category={'hair'} />
      <div className='Page-Content'>
        <div className='Content-Container'>
          <div className='About-Page-Content' >
            <AboutPageTitle ref={titleRef} />
            <img className='About-Page-Img' src={require('../../assets/img/About-Page-Img.webp')} alt='' ref={imgRef} />
            <AboutEng ref={engAboutRef} />
            <AboutChi ref={chiAboutRef} />
          </div>
        </div>
        <TopButton category='' />
      </div>
      <Footer />
    </div>
  );
}

export default AboutPage;
