import React from "react";
import '../scss/Common.css';
import '../scss/LatestSwiper.css';
import '../scss/Category.css';

interface Props {
  category: string;
}

// big hash background image of different colors
const HashBg: React.FC<Props> = (props: Props) => {
  return (
    // change to active slide opacity 100%
    <div className='Content-Container'>
      <div className='Hash-Bg People-Hash' />
      <div className='Hash-Bg Hair-Hash' />
      <div className='Hash-Bg Beauty-Hash' />
      <div className='Hash-Bg Fashion-Hash' />
      <div className='Hash-Bg Mind-Hash' />
      <div className='Hash-Bg Living-Hash' />
    </div>
  );
}

export default HashBg;
